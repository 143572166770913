import React from 'react';

export default class FileUploader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    var $form = $('.box');

    if (this.isAdvancedUpload()) {
      $form.addClass('has-advanced-upload');
    }
  }

  handleChange(e) {
    let files = e.target.files;
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {

      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          name: file.name,
          type: file.name.split('.').pop(),
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result.replace(/^data:(image\/png|image\/jpg|application\/pdf|application\/doc|application\/docx|image\/jpeg);base64,/, ""),
          file: file,
        };
        if (this.props.allowedFileTypes.includes(file.name.split('.').pop())) {
          allFiles.push(fileInfo);
        }

        if (allFiles.length == files.length) {
          this.props.addFilesToState(allFiles);
        }
      }
    }
  }

  isAdvancedUpload() {
    var div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  };

  removeImage() {
    this.props.removeImage(file);
  }

  renderUploadedFiles() {
    if (this.props.files.length > 0) {

      let allFiles = this.props.files.map((file) => {

        return (
          <div className="photo-container d-flex align-items-center">
            <div className="photo-text d-flex align-items-center">
              <svg className="mr-2" width="14px" height="19px" viewBox="0 0 14 19" version="1.1">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Login-7" transform="translate(-564.000000, -786.000000)" stroke="#6B8F9C">
                        <polygon id="Path-5" points="577 804 565 804 565 787 572.493381 787 577 791.436567"></polygon>
                    </g>
                </g>
              </svg>
              <div className="file-name">{file.name}</div>
              <div className="erase-btn" onClick={this.props.removeImage.bind(this, file)}> 
                <svg width="14px" height="13px" viewBox="0 0 14 13" version="1.1">
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                      <g id="Login-7" transform="translate(-1223.000000, -789.000000)" stroke="#6B8F9C">
                          <g id="Group" transform="translate(1224.000000, 789.000000)">
                              <line x1="12.0208153" y1="3.37507799e-14" x2="5.24025268e-14" y2="12.0208153" id="Line"></line>
                              <line x1="12.0208153" y1="3.37507799e-14" x2="5.24025268e-14" y2="12.0208153" id="Line-Copy" transform="translate(6.010408, 6.010408) scale(-1, 1) translate(-6.010408, -6.010408) "></line>
                          </g>
                      </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        )
      })
      return <div className="uploaded-photo-container">{allFiles}</div>;
    }
  }

  render() {
    let disabled =  {}
    if (Object.keys(this.props).includes('disabled')) {
      disabled['disabled'] = 'disabled'
    }

    return (
      <>
          <div className={`add-photo-container${this.props.disabled ? ' disabled' : ''}`}>
            <div className="photo-added-container">
            <div className="photos-container">
                {this.renderUploadedFiles()}
              </div>
              <form className="file-uploader box" method="post" action="" encType="multipart/form-data" >
                <div className="box__input">
                  <input
                    id={`file-${parseInt(Math.random()*10000000)}`}
                    className="box__file"
                    type="file"
                    onChange={this.handleChange.bind(this)}
                    multiple={this.props.multiple}
                    accept={this.props.allowedFileTypes.map(f => `.${f}`).join(',')}
                    {...disabled}
                  />
                  <div className="box__dragndrop">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 49">
                      <defs />
                      <path fill="#A4A4A4" d="M25.6 15.4L15.3 31.3h5.2v17.2h10.3V31.3H36zM22.2 13c0-.2-.2-.4-.4-.4H8c-.2 0-.4.2-.4.4s.2.4.4.4h13.7c.3 0 .5-.2.5-.4zM18.8 18c0-.2-.2-.4-.4-.4H8c-.2 0-.4.2-.4.4s.2.4.4.4h10.4c.2 0 .4-.2.4-.4zM16.7 23c0-.2-.2-.4-.4-.4H8c-.2 0-.4.2-.4.4s.2.4.4.4h8.2c.3 0 .5-.2.5-.4zM8 27.6c-.2 0-.4.2-.4.4s.2.4.4.4h5.5c.2 0 .4-.2.4-.4s-.2-.4-.4-.4H8z" />
                      <path fill="#A4A4A4" d="M.8 37.6V3.4c0-1.1.9-2 2-2h20.5L29 7.5v8h.8V7.2L23.6.6H2.8C1.2.6 0 1.9 0 3.4v34.1c0 1.6 1.2 2.9 2.8 2.9h15.1v-.8H2.8c-1.1 0-2-.9-2-2z" />
                      <path fill="#A4A4A4" d="M27.1 8.3h-5V3.4h-.7v5.7h5.7z" />
                    </svg>
                    <label htmlFor="file">Smelltu hér til að sækja skjöl eða dragðu þau inn</label>
                  </div>
                </div>
              </form>
            </div>
            {this.state.photoUploadError && <div>{this.state.photoUploadError}</div>}
          </div>
      </>
    )
  }
}

FileUploader.defaultProps = {
  allowedFileTypes: ['jpg', 'png', 'heic', 'pdf', 'jpeg', 'JPG', 'PNG', 'HEIC', 'PDF', 'JPEG', 'doc', 'docx']
};