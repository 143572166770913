import React from "react";
import { connect } from 'react-redux'
//import { createEstimate, deleteEstimate } from "../../api/settlement-line/actions";
import Iframe from 'react-iframe'
import { formatMoney } from "../../utils";
import FileUploader from "../../components/file-uploader";

import { fetchApplication, updateApplication, sendHealthProviderEmail } from "../../api/applications/actions";
import { deleteApplicationDocument } from "../../api/application-documents/actions";
import DatePicker from 'react-date-picker'
import moment from 'moment'


class Estimate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            [`insurance_provide_display`]: false,
            files: [],
            error: '',
            updatingTexts: false,
            stabilityPoint: null,
        }
        this.estimate_doctor = React.createRef()
        this.estimate_lawyer = React.createRef()
        this.insurance_number = React.createRef()

        this.deleteDocument = this.deleteDocument.bind(this)
        this.shouldShowEditButtons = this.shouldShowEditButtons.bind(this)
        this.shouldShowEstimateUpload = this.shouldShowEstimateUpload.bind(this)
    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.applications.application) !== JSON.stringify(prevProps.applications.application) && this.props.applications.application.jsondata.stability_point) {
            if (this.props.applications.application.jsondata.stability_point !== '' && this.props.applications.application.jsondata.stability_point !== null) {
                this.setState({stabilityPoint: moment(this.props.applications.application.jsondata.stability_point, "DD.MM.YYYY").toDate()})
            }
        }
    }

    onChange(e) {
        const { value, id } = e.currentTarget
        this.setState({
            [`${id}_display`]: value == 'other'
        })

        this.props.onChange(value, id)
    }

    onChangeSelect(item, id) {
        let value = item.value

        this.setState({
            [`${id}_display`]: value === 'other'
        })

        this.props.onChange(value, id)
    }

    addFilesToState(files) {
        let oldFiles = this.state.files
        let newFiles = oldFiles.concat(files)

        this.setState({files: newFiles})
    }

    removeImage(file) {
        let newFiles = []
        this.state.files.map((f) => {
            if (f.base64 !== file.base64) {
                newFiles.push(f)
            }
            return newFiles
        })

        this.setState({files: newFiles})
    }

    deleteDocument(doc) {
        if (window.confirm(`Ertu viss um að þú eyða skjalinu ${doc.title}?`)) {
            Promise.resolve(this.props.dispatch(deleteApplicationDocument(doc)))
            .then(response => {
                this.props.dispatch(fetchApplication(false, this.props.applications.application.id))
            })
        }
    }

    saveTexts() {
        this.setState({ updatingTexts: true, error: '' })
        Promise.resolve(this.props.dispatch(updateApplication({
            'estimate_doctor': this.estimate_doctor.current.value,
            'estimate_lawyer': this.estimate_lawyer.current.value,
            'stability_point': this.state.stabilityPoint ? moment(this.state.stabilityPoint).format("DD.MM.YYYY") : '',
            'insurance_number': this.insurance_number.current.value,
            'files': this.state.files.map(f => { f.document_type = 'estimate'; return f;})
        }, this.props.applicationId)))
            .then(() => setTimeout(() => this.setState({ updatingTexts: false, files: [] }), 500), (e) => { setTimeout(() => this.setState({ updatingTexts: false, files: [], error: 'Villa kom upp við að vista texta' }), 500) })
    }


    sendHealthProviderEmail() {
      Promise.resolve(this.props.dispatch(sendHealthProviderEmail(this.props.applicationId)));

    }


    shouldShowEditButtons() {
        return ['in_progress', 'estimate_sent'].includes(this.props.applications.application.state)
    }


    shouldShowEstimateUpload() {
        return ['estimate_sent'].includes(this.props.applications.application.state)
    }

    render() {


        return (
            <>
                {this.props.applications.application && this.props.applications.application.settlements &&
                    <>
                        {this.props.applications.application.state === 'in_progress' &&
                        <div className="row pn-buttons my-1">
                          <div className="col mb-5">
                            <button className="w-auto px-3 btn-small" onClick={() => this.sendHealthProviderEmail()} disabled={this.props.applications.healthProviderEmailMessage !== ''}>{ this.props.applications.healthProviderEmailLoading ? "Sendi..." : "Senda beiðni um lokavottorð"}</button>
                            {this.props.applications.healthProviderEmailMessage !== '' && <>{this.props.applications.healthProviderEmailMessage}</>}
                          </div>
                        </div>
                        }
                        <div className="row">
                            <div className="col-8">
                                <label htmlFor="stability_point">Stöðugleikapunktur</label>
                                <div className="input-group">
                                    <DatePicker
                                        onChange={(value) => this.setState({ stabilityPoint: value })}
                                        minDate={moment(this.props.applications.application.jsondata.accident_date).toDate()}
                                        maxDate={moment().toDate()}
                                        value={(this.state.stabilityPoint !== '' && this.state.stabilityPoint !== null )? moment(this.state.stabilityPoint, 'DD.MM.YYYY').toDate() : null}
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <label htmlFor="insurance_number">Tjónsnúmer</label>
                                <div className="input-group">
                                    <input className="form-control" id="insurance_number" ref={this.insurance_number} defaultValue={this.props.applications.application.jsondata.insurance_number || ''} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <label htmlFor="estimate_doctor">Nafn læknis fyrir matsgerð</label>
                                <div className="input-group">
                                    <input className="form-control" id="estimate_doctor" ref={this.estimate_doctor} defaultValue={this.props.applications.application.jsondata.estimate_doctor || ''} />
                                </div>
                            </div>
                            <div className="col-8">
                                <label htmlFor="estimate_lawyer">Nafn lögfræðings fyrir matsgerð</label>
                                <div className="input-group">
                                    <input className="form-control" id="estimate_lawyer" ref={this.estimate_lawyer} defaultValue={this.props.applications.application.jsondata.estimate_lawyer || ''} />
                                </div>
                            </div>
                        </div>
                        <div className="row pn-buttons my-4">
                            { this.shouldShowEditButtons() && <div className="col">
                                <button className="btn-small m-auto" onClick={() => this.saveTexts()}>{this.state.updatingTexts ? 'Augnablik...' : 'Vista'}</button>
                                {this.state.error && this.state.error}
                            </div> }
                        </div>

                        { this.shouldShowEstimateUpload() &&
                            <>
                            <p><strong>Matsgerð</strong></p>
                            <p>Hér er matsgerð hlaðið inn. Matsgerð fylgir með kröfubréfi þegar það er að lokum sent.</p>
                            <FileUploader
                                removeImage={this.removeImage.bind(this)}
                                multiple={true}
                                addFilesToState={this.addFilesToState.bind(this)}
                                files={this.state.files} 
                                allowedFileTypes={['pdf', 'PDF']}/>
                            </>
                        }
                        {this.props.applications.application && this.props.applications.application.application_document && this.props.applications.application.application_document.filter(f => f.document_type === 'estimate').map(doc => 
                            <div class="mt-5 btn-group">
                                <a target="_blank" className="mb-2 btn btn-outline-primary btn-sm" href={doc.url}>
                                    {doc.title}
                                </a>
                                <a className="btn btn-danger btn-sm mr-2 mb-2" onClick={() => this.deleteDocument(doc)}>
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </a>
                            </div>
                        )}

                    </>
                }
            </>
        );
    }
}

function mapStateToProps(state) {

    var f = {
        applications: {},
    }
    f.applications = (state.applications === undefined) ? {} : state.applications

    return f;
}

export default connect(mapStateToProps)(Estimate);