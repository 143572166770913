import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError, getCookie } from '../../utils'
import { setInfoKey } from '../information-collection/actions'
export const applicationDocumentTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'APPLICATION_DOCUMENT');

export const requestDeleteApplicationDocument = () => {
  return {
      type: applicationDocumentTypes.REQUEST,
  };
};

export const receiveDeleteApplicationDocument = (response, query = {}) => {
  return {
      type: applicationDocumentTypes.RECEIVE,
      query: query,
      data: response,
  };
};

export const receiveDeleteApplicationDocumentError = () => {
  return {
      type: applicationDocumentTypes.ERROR,
  };
};

export const deleteApplicationDocument = (document) => {
  return dispatch => {
      dispatch(requestDeleteApplicationDocument());
        
      let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
      }

      fetch(`/api/v1/application_documents/${document.id}/`, {
          headers: headers,
          method: 'DELETE',
          credentials: 'include',
      })
          .then(response => {
              try {
                  dispatch(receiveDeleteApplicationDocument(response));
              } catch (e) {
                  dispatch(receiveDeleteApplicationDocumentError(response))
              }
          })
          .catch(handleNetworkError);
  }
}