import React from 'react';

import { profileTypes } from './actions'

const initialState = {
    isFetching: false,
    results: [],
}



export function profile(state = initialState, action) {

    switch (action.type) {

        case profileTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state


        case profileTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.results = {
                'name': action.data.user.first_name,
                'email': action.data.user.email,
                'address': action.data.address,
                'phone': action.data.phone,
                'ssn': action.data.ssn,
                'city': action.data.city,
                'postal_code': action.data.postal_code,
            }

            return state

        default:
            return state
    }
}