import $ from 'jquery';
window.$ = $;
window.jQuery = $;
const bootstrap = require('bootstrap');

require('./views/application-form')
require('./views/application-status')
require('./views/application-detail')
require('./views/settlement')
require('./views/estimate')

import "../../sass/main.scss"
