import { stepRenderTypes } from './actions'

export function stepRenderer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case stepRenderTypes.INIT_STEPPER: 
            state = { ...state }
            state.stepper = action.stepper,
            state.index = action.index
            return state

        case stepRenderTypes.SET_STEP:
            state = { ...state }
            state.index = action.data
            return state

        default:
            return state
    }

}