import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError, getCookie } from '../../utils'
import { setInfoKey } from '../information-collection/actions'
export const createApplicationTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'CREATE_APPLICATION');
export const updateApplicationTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'UPDATE_APPLICATION');
export const applicationTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'APPLICATION');
export const healthProviderEmailTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'HEALTH_PROVIDER');

export const requestCreateApplication = () => {
  return {
      type: createApplicationTypes.REQUEST,
  };
};

export const receiveCreateApplication = (response, query = {}) => {
  return {
      type: createApplicationTypes.RECEIVE,
      query: query,
      data: response,
  };
};

export const receiveCreateApplicationError = () => {
  return {
      type: createApplicationTypes.ERROR,
  };
};

export const createApplication = (application) => {
  return dispatch => {
      dispatch(requestCreateApplication());
        
      let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
      }

      fetch(`/api/v1/applications/`, {
          headers: headers,
          method: 'POST',
          body: JSON.stringify(application),
          credentials: 'include',
      })
          .then(parseJSON)
          .then(response => {
              try {
                  dispatch(receiveCreateApplication(response));
              } catch (e) {
                  dispatch(receiveCreateApplicationError(response))
              }
          })
          .catch(handleNetworkError);
  }
}

export const requestUpdateApplication = () => {
    return {
        type: updateApplicationTypes.REQUEST,
    };
  };
  
  export const receiveUpdateApplication = (response, query = {}) => {
    return {
        type: updateApplicationTypes.RECEIVE,
        query: query,
        data: response,
    };
  };
  
  export const receiveUpdateApplicationError = () => {
    return {
        type: updateApplicationTypes.ERROR,
    };
  };
  
  export const updateApplication = (application, applicationId) => {
    return dispatch => {
        dispatch(requestUpdateApplication());
          
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
            credentials: 'include',
        }
  
        fetch(`/api/v1/applications/${applicationId}/`, {
            headers: headers,
            method: 'PATCH',
            body: JSON.stringify(application),
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveUpdateApplication(response));
                } catch (e) {
                    dispatch(receiveUpdateApplicationError(response))
                }
            })
            .catch(handleNetworkError);
    }
  }


export const requestApplication = () => {
  return {
      type: applicationTypes.REQUEST,
  };
};

export const receiveApplication = (response, query = {}) => {
  return {
      type: applicationTypes.RECEIVE,
      query: query,
      data: response,
  };
};

export const receiveApplicationError = () => {
  return {
      type: applicationTypes.ERROR,
  };
};

export const fetchApplication = (shouldSetInfoKeys, application_id) => {
  return dispatch => {
      dispatch(requestApplication());
        
      let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }

      fetch(`/api/v1/applications/${application_id}/`, {
          headers: headers,
          method: 'GET',
      })
          .then(parseJSON)
          .then(response => {
              try {
                  Promise.resolve(dispatch(receiveApplication(response)))
                  .then(()=> {
                      if (response.jsondata && shouldSetInfoKeys) {
                        Object.keys(response.jsondata).map((key) => dispatch(setInfoKey(key,response.jsondata[key])))
                    }
                  })
              } catch (e) {
                  dispatch(receiveApplicationError(response))
              }
          })
          .catch(handleNetworkError);
  }
}

export const requestHealthProviderEmail = () => {
    return {
        type: healthProviderEmailTypes.REQUEST,
    };
  };
  
  export const receiveHealthProviderEmail = (response) => {
    return {
        type: healthProviderEmailTypes.RECEIVE,
        data: response,
    };
  };
  
  export const receiveHealthProviderEmailError = (response) => {
    return {
        type: healthProviderEmailTypes.ERROR,
        data: response,
    };
  };

export const sendHealthProviderEmail = (applicationId) => {
    return dispatch => {
        dispatch(requestHealthProviderEmail())

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
            credentials: 'include',
        }
  
        fetch(`/api/v1/applications/${applicationId}/send_hp_final_email/`, {
            headers: headers,
            method: 'GET',
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveHealthProviderEmail(response));
                } catch (e) {
                    dispatch(receiveHealthProviderEmailError(response))
                }
            })
            .catch(handleNetworkError);
    }
  }