export const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: '#C6C6C6',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
  }),
  input: (provided, state) => ({
    ...provided,
    'height': '37px'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    'display': 'none'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    'top': '60%'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    'top': '60%'
  }),
}

export const selectOptions = {
  noOptionsMessage: () => "Ekkert fannst",
  placeholder: "Veldu...",
  styles: customStyles,
  menuPlacement: "bottom"
}

