import React from 'react';
import DatePicker from 'react-date-picker'
import AdditionalQuestions from '../additional-questions'
import Select from 'react-select'
import {selectOptions, customStyles} from './select'
import moment from 'moment'

class Step2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      healthcare_provider_display: false,
      accident_type_display: false,
      errors: {
        age: '',
        ageContractEnd: '',
        monthlyinsurance: '',
        deathbenefits: '',
        insurancetaker: '',
        beneficiary: '',
    }
    }
    this.onDateChange = this.onDateChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);

    this.accident_location = React.createRef()
    this.monthlyinsurance = React.createRef()
    this.deathbenefits = React.createRef()
    this.insurancetaker = React.createRef()
  }

  convertToBool(val){
    if (val === 'true') {
      return true
    }
    if (val === "false") {
      return false
    }
  }

  onRadioChange(e) {
    const { value, name } = e.target
    this.props.onChange(value == "false" || value == "true" ? this.convertToBool(value) : value, name)
  }

  onChange(e) {
    const { value, id } = e.currentTarget
    this.setState({
      [`${id}_display`]: value === 'other'
    })

    this.props.onChange(value, id)
  }

  onChangeSelect(item, id) {
    let value = item.value

    this.setState({
      [`${id}_display`]: value === 'other'
    })

    this.props.onChange(value, id)
  }


  onDateChange(val, type) {
    this.props.onChange(val, type)
  }

  getAccidentType() {
    let accType = this.props.accidentType.results.find(t => ""+t.id == this.props.infoCollection.accident_type);
    if (accType) {
      return accType
    } else {
      return { name: "other" }
    }
  }


  render() {
    let readOnly = {}
    let disabled = {}
    let selectDisabled = {}
    if (!this.props.editable)  {
        readOnly['readOnly'] = "readonly"
        disabled['disabled'] = "disabled"
        selectDisabled['isDisabled'] = true
    }
    
    let {infoCollection} = this.props;

    let accidentTypeOptions = this.props.accidentType.results.map((a) => {return {value: ""+a.id, label: a.name}})
    accidentTypeOptions.push({value: 'other', label: 'Annað'})
    let accidentTypeValue = accidentTypeOptions.filter(f => f.value === infoCollection.accident_type)
    let healthcareProviderOptions = this.props.healthcareProvider.results.map((h) => {return {value: ""+h.id, label: `${h.name}, ${h.postalcode} ${h.city}`}})
    healthcareProviderOptions.push({value: 'other', label: 'Annað'})
    let healthcareProviderValue = healthcareProviderOptions.filter(f => f.value === infoCollection.healthcare_provider)
    
    return (
      <fieldset disabled={this.props.isSuperUser == 'False'}>
        <div className="container-fluid step-2 d-flex align-items-center justify-content-center">
          <div className="step-2-col">
          <div className="w-100 mb-3">
            <label htmlFor="accident_type">Tegund tjóns</label>
            <Select {...selectDisabled} {...selectOptions} id="accident_type" options={accidentTypeOptions} value={accidentTypeValue} onChange={(e) => this.onChangeSelect(e, "accident_type")} />
            {this.state[`accident_type_display`] && <div><label className="mt-3" htmlFor="accident_type_other">Hvað?</label><input id="accident_type_other" value={infoCollection.accident_type_other} onChange={(e) => this.onChange(e)}></input></div>}
          </div>
          <div className="w-100 mb-3">
            <label htmlFor="accident_date">Dagsetning tjóns</label>
            <DatePicker
              onChange={(value) => this.onDateChange(value, "accident_date")}
              value={infoCollection.accident_date ? moment(infoCollection.accident_date).toDate() : null} 
              maxDate={moment().toDate()}
              disabled={this.props.isSuperUser === 'False' || !this.props.editable}/>
          </div>
          <div className="w-100 mb-3">
            <label htmlFor="accident_location">Hvar átti tjónið sér stað?</label>
            <input {...readOnly} type="text" id="accident_location" value={infoCollection.accident_location} onChange={(e) => this.onChange(e)} disabled={this.props.isSuperUser == 'False'}></input>
          </div>
          <div className="w-100 mb-3">
            <label htmlFor="accident_description">Stutt lýsing á tjóni</label>
            <textarea {...readOnly} type="text" id="accident_description" value={infoCollection.accident_description} onChange={(e) => this.onChange(e)}></textarea>
          </div>
          <div className="w-100 mb-3">
            <label htmlFor="accident_injuries">Hvaða meiðsli hlutust af?</label>
            <input {...readOnly} type="text" id="accident_injuries" value={infoCollection.accident_injuries} onChange={(e) => this.onChange(e)}></input>
          </div>

          {infoCollection.accident_type && <AdditionalQuestions onChange={this.onChange} onRadioChange={this.onRadioChange} onDateChange={this.onDateChange} accident_type={this.getAccidentType()} infoCollection={infoCollection} isSuperUser={this.props.isSuperUser} editable={this.props.editable} readOnly={readOnly} disabled={disabled} selectDisabled={selectDisabled} />}
          
          <div  className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.onRadioChange(e)}>
            <div className="title">Varstu undir áhrifum áfengis og/eða lyfja?</div>
            <input {...disabled} className="ml-auto mr-2" type="radio" id="accident_alcohol_medicin_yes" name="accident_alcohol_medicin" checked={infoCollection.accident_alcohol_medicin === true} value={true} />
            <label className="mr-2 mb-0" htmlFor="accident_alcohol_medicin_yes">Já</label>
            <input {...disabled} className="mr-2" type="radio" id="accident_alcohol_medicin_no" name="accident_alcohol_medicin" checked={infoCollection.accident_alcohol_medicin === false} value={false} />
            <label className="mb-0" htmlFor="accident_alcohol_medicin_no">Nei</label>
          </div>
          <div className="question-container w-100 mb-3 flex-wrap">
            <div className="title mb-2">Gerðist slysið á vinnutíma, á beinni leið í eða úr vinnu eða í frítíma þínum?</div>
            <div className="w-100 d-flex align-items-center radio flex-wrap multiple-inputs"onChange={(e) => this.onRadioChange(e)}>
              <input {...disabled} className="mr-2" type="radio" id="worktime" name="accident_time" value="worktime" checked={infoCollection.accident_time == 'worktime'} />
              <label className="mr-4 mb-0" htmlFor="worktime">Á vinnutíma</label>
              <input {...disabled} className="mr-2" type="radio" id="on_way_to_from_work" name="accident_time" value="on_way_to_from_work" checked={infoCollection.accident_time == 'on_way_to_from_work'} />
              <label className="mr-4 mb-0" htmlFor="on_way_to_from_work">Á beinni leið í eða úr vinnu</label>
              <input {...disabled} className="mr-2" type="radio" id="vacation" name="accident_time" value="vacation" checked={infoCollection.accident_time == 'vacation'} />
              <label className="mb-0" htmlFor="vacation">Í frítíma</label>
            </div>
          </div>
          <div className="question-container w-100 mb-3 flex-wrap">
            <div className="d-flex align-items-center radio flex-wrap" onChange={(e) => this.onRadioChange(e)}>
              <div className="title">Voru vitni að slysinu?</div>
              <input {...disabled} className="ml-auto mr-2" type="radio" id="accident_witnesses_yes" name="accident_witnesses" checked={infoCollection.accident_witnesses === true} value={true} />
              <label className="mr-4 mb-0" htmlFor="accident_witnesses_yes">Já</label>
              <input {...disabled} className="mr-2" type="radio" id="accident_witnesses_no" name="accident_witnesses" checked={infoCollection.accident_witnesses === false} value={false} />
              <label className="mb-0" htmlFor="accident_witnesses_no">Nei</label>
            </div>
            {infoCollection.accident_witnesses &&
              <div className="w-100">
                <label className="mb-2" htmlFor="accident_witnesses_list">Vinsamlegast gefið upp nöfn og símanúmer.</label>
                <textarea {...readOnly} className="mb-0" type="text" id="accident_witnesses_list" value={infoCollection.accident_witnesses_list} onChange={(e) => this.onChange(e)}></textarea>
              </div>
            }
          </div>
          <div className="question-container w-100 d-flex align-items-center radio mb-3" onChange={(e) => this.onRadioChange(e)}>
            <div className="w-100">Var lögregla kölluð til?</div>
            <input {...disabled} className="ml-auto mr-2" type="radio" id="accident_police_yes" name="accident_police" checked={infoCollection.accident_police === true} value={true} />
            <label className="mr-4 mb-0" htmlFor="accident_police_yes">Já</label>
            <input {...disabled} className="mr-2" type="radio" id="accident_police_no" name="accident_police" checked={infoCollection.accident_police === false} value={false} />
            <label className="mb-0" htmlFor="accident_police_no">Nei</label>
          </div>
          <div className="w-100 mb-3">
            <label htmlFor="doctors_date">Hvenær leitaðir þú til læknis?</label>
            <DatePicker
              onChange={(value) => this.onDateChange(value, "doctors_date")}
              minDate={moment(infoCollection.accident_date).toDate()}
              maxDate={moment().toDate()}
              value={infoCollection.doctors_date ? moment(infoCollection.doctors_date).toDate() : null} 
              disabled={this.props.isSuperUser === 'False' || !this.props.editable}/>
          </div>
          <div className="w-100 mb-3">
            <label htmlFor="doctors_name">Hvað er nafn læknisins?</label>
            <input {...readOnly} type="text" id="doctors_name" value={infoCollection.doctors_name} onChange={(e) => this.onChange(e)}></input>
          </div>
          <div className="w-100 mb-3">
            <label htmlFor="healthcare_provider">Hvert er nafn sjúkrastofnunarinnar?</label>
            <Select {...selectDisabled} {...selectOptions} id="healthcare_provider" options={healthcareProviderOptions} value={healthcareProviderValue} onChange={(e) => this.onChangeSelect(e, "healthcare_provider")} />
            {this.state[`healthcare_provider_display`] && <div><label htmlFor="healthcare_provider_other">Hvað?</label><input type="text" id="healthcare_provider_other" value={infoCollection.healthcare_provider_other} onChange={(e) => this.onChange(e)}></input></div>}
            </div>
          <div className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.onRadioChange(e)}>
            <div className="title">Ertu með heimilislækni?</div>
              <input {...disabled} className="ml-auto mr-2" type="radio" id="has_home_doctor_yes" name="has_home_doctor" checked={infoCollection.has_home_doctor === true} value={true} />
              <label className="mr-4 mb-0" htmlFor="has_home_doctor_yes">Já</label>
              <input {...disabled} className="mr-2" type="radio" id="has_home_doctor_no" name="has_home_doctor" checked={infoCollection.has_home_doctor === false} value={false} />
              <label className="mb-0" htmlFor="has_home_doctor_no">Nei</label>
              {infoCollection.has_home_doctor &&
                <div className="w-100">
                  <label htmlFor="home_doctor_name">Hver er heimilislæknirinn þinn?</label>
                  <input {...readOnly} type="text" id="home_doctor_name" value={infoCollection.home_doctor_name} onChange={(e) => this.onChange(e)}></input>
                </div>
              }
          </div>
          <div className="w-100 mb-3">
            <label htmlFor="other_caretakers">Nöfn annarra meðferðaraðila, s.s. sjúkraþjálfara eða hnykkjara (ef við á)?</label>
            <input {...readOnly} type="text" id="other_caretakers" value={infoCollection.other_caretakers} onChange={(e) => this.onChange(e)}></input>
          </div>
          <div  className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.onRadioChange(e)}>
            <div className="title">Varstu óvinnufær vegna slyssins?</div>
            <input {...disabled} className="ml-auto mr-2" type="radio" id="unable_to_work_yes" name="unable_to_work" checked={infoCollection.unable_to_work === true} value={true} />
            <label className="mr-4 mb-0" htmlFor="unable_to_work_yes">Já</label>
            <input {...disabled} className="mr-2" type="radio" id="unable_to_work_no" name="unable_to_work" checked={infoCollection.unable_to_work === false} value={false} />
            <label className="mb-0" htmlFor="unable_to_work_no">Nei</label>
            {infoCollection.unable_to_work &&
              <div className="w-100">
                <label>Hversu lengi?</label>
                <label htmlFor="unable_to_work_start">Frá:</label>
                <DatePicker
                    onChange={(value) => this.onDateChange(value, "unable_to_work_start")}
                    minDate={moment(infoCollection.accident_date).toDate()}
                    value={infoCollection.unable_to_work_start ? moment(infoCollection.unable_to_work_start).toDate() : null} 
                    disabled={this.props.isSuperUser === 'False' || !this.props.editable}/>
                <label className="mt-3" htmlFor="unable_to_work_end">Til:</label>
                <DatePicker
                    onChange={(value) => this.onDateChange(value, "unable_to_work_end")}
                    minDate={moment(infoCollection.unable_to_work_start ? infoCollection.unable_to_work_start : infoCollection.accident_date).toDate()}
                    value={infoCollection.unable_to_work_end ? moment(infoCollection.unable_to_work_end).toDate() : null} 
                    disabled={this.props.isSuperUser === 'False' || !this.props.editable}
                    />
              </div>
            }
          </div>

          <div className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.onRadioChange(e)}>
            <div className="title">Hefur þú verið metin/n til örorku?</div>
            <input {...disabled} className="ml-auto mr-2" type="radio" id="disabled_after_accident_yes" name="disabled_after_accident" checked={infoCollection.disabled_after_accident === true} value={true} />
            <label className="mr-4 mb-0" htmlFor="disabled_after_accident_yes">Já</label>
            <input {...disabled} className="mr-2" type="radio" id="disabled_after_accident_no" name="disabled_after_accident" checked={infoCollection.disabled_after_accident === false} value={false} />
            <label className="mb-0"  htmlFor="disabled_after_accident_no">Nei</label>
          {infoCollection.disabled_after_accident && 
            <div className="w-100">
              <label className="mb-2" htmlFor="disabled_after_accident_who_how_much">Hver framkvæmdi matið og hver var örorkan í prósentum?</label>
              <input {...readOnly} className="mb-0" id="disabled_after_accident_who_how_much" value={infoCollection.disabled_after_accident_who_how_much} onChange={(e) => this.onChange(e)}></input>
            </div>
          }
          </div>

          <div className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.onRadioChange(e)}>
            <div className="title">Áttir þú við einhver veikindi eða meiðsli að stríða fyrir slysið?</div>
            <input {...disabled} className="ml-auto mr-2" type="radio" id="injuries_after_accident_yes" name="injuries_after_accident" checked={infoCollection.injuries_after_accident === true} value={true} />
            <label className="mr-4 mb-0" htmlFor="injuries_after_accident_yes">Já</label>
            <input {...disabled} className="mr-2" type="radio" id="injuries_after_accident_no" name="injuries_after_accident" checked={infoCollection.injuries_after_accident === false} value={false} />
            <label className="mb-0" htmlFor="injuries_after_accident_no">Nei</label>
          {infoCollection.injuries_after_accident && 
            <div className="w-100">
              <label className="mb-2" htmlFor="injuries_after_accident_which_injury_doctor">Hvaða veikindi/meiðsli og hver er meðferðarlæknir þinn?</label>
              <input {...readOnly} className="mb-0" id="injuries_after_accident_which_injury_doctor" value={infoCollection.injuries_after_accident_which_injury_doctor} onChange={(e) => this.onChange(e)}></input>
            </div>
          }
          </div>

        </div>
      </div>
      </fieldset>
    )
  }
}
Step2.defaultProps = {
  editable: true
}

export default Step2;
