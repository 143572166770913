import Step1 from '../components/application-process/step1'
import Step2 from '../components/application-process/step2'
import Step3 from '../components/application-process/step3'
import Terms from '../components/application-process/terms'
import FinalStep from '../components/application-process/final-step'
import UpdatedView from '../components/application-process/updated-view'
import accounting from 'accounting'

export function parseJSON(response) {
  return response.json();
}

export const handleNetworkError = (error) => {
  if (window.Raven !== undefined) {
    Raven.captureException(error)
  } else {
    throw (error)
  }
}

// Stepper for application form
export const getStepComponent = (step, context, status) => {

  switch (context) {
    case 'application-process':
      switch (step) {
        case 'step1':
          return Step1
        case 'step2':
          return Step2
        case 'step3':
          return Step3
        case 'terms':
          return Terms
        case 'final':
          if (status === 'update') {
            return UpdatedView
          } else {
            return FinalStep
          }

        default:
          return Step1
      }
  }
}

export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}

export const step1validated = (state) => {

  let ready = []

    Object.entries(state).map(([key,val]) => {
      if (key === 'email') {
        if (val !== '' && looksLikeMail(val)) {
          ready.push(true)
        } else {
          ready.push(false)
        }
      }
      else if (key === 'phone') {
        if (val !== '' && looksLikePhone(val)) {
          ready.push(true)
        } else {
          ready.push(false)
        }
      }
      else {
          if (val !== '' && val !== null) {
            ready.push(true)
          } else {
            ready.push(false)
        }
      }
    })
  return ready.every((val) => val === true)
}

export const step2validated = (props) => {

  let ready = []

  Object.entries(props.infoCollection).map(([key,val]) => {
    if (!(key.includes('bank') || key.includes('insurance_provider'))) {
      if (key === 'files' || key === 'other_caretakers') {
        ready.push(true)
      } else {
        if (val !== '' && val !== null) {
          ready.push(true)
        } else {
          ready.push(false)
        }
      }
    }
  })
  return ready.every((val) => val === true)
}

export const step3validated = (props) => {

  let ready = []
  
  Object.entries(props.infoCollection).map(([key,val]) => {
    if ((key.includes('bank') || key === 'insurance_provider')) {
      if (val !== '') {
        ready.push(true)
      } else {
        ready.push(false)
      }
    }
  })

  return ready.every((val) => val === true)
}

export const termsvalidated = (props) => {

  let ready = []
  
  Object.entries(props.infoCollection).map(([key,val]) => {
    if ((key.includes('terms') )) {
      if (val === true) {
        ready.push(true)
      } else {
        ready.push(false)
      }
    }
  })

  return ready.length > 0 && ready.every((val) => val === true)

}

export const  looksLikeMail = (str) => {
  var lastAtPos = str.lastIndexOf('@');
  var lastDotPos = str.lastIndexOf('.');
  return (lastAtPos < lastDotPos && lastAtPos > 0 && str.indexOf('@@') == -1 && lastDotPos > 2 && (str.length - lastDotPos) > 2);
}
export const  looksLikePhone = (str) => {

  const regex = /(\+)?(\d{1,3})?\d{7}/
  return (str !== null && str.match(regex))
}

export const b64DecodeUnicode = (str) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}


export const formatMoney = (num, decimals = 0) => {
  return accounting.formatMoney(+num, '', decimals, ".", ",")
}