import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { stepRenderer } from './api/step-renderer/reducers'
import { insuranceProvider } from './api/insurance-provider/reducers'
import { healthcareProvider } from './api/healthcare-provider/reducers'
import { accidentType } from './api/accident-type/reducers'
import { profile } from './api/profile/reducers'
import { infoCollection } from './api/information-collection/reducers'
import { applications } from './api/applications/reducers'

import { composeWithDevTools } from 'redux-devtools-extension';


const loggerMiddleware = createLogger()

const reducers = combineReducers({
    stepRenderer,
    insuranceProvider,
    healthcareProvider,
    accidentType,
    profile,
    infoCollection,
    applications,
})


let middleware = null
if(process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
        thunkMiddleware,
        
    )
} else {
    middleware = composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ))
}

export const store = createStore(reducers, middleware)
