import React from "react";
import { connect } from 'react-redux'
import { createSettlement, deleteSettlement } from "../../api/settlement-line/actions";
import { updateApplication } from "../../api/applications/actions";
import Iframe from 'react-iframe'
import { formatMoney } from "../../utils";

const settlementTypeTexts = {
    'benefits': 'Bætur/vextir',
    'benefits_text': 'Fyrirsögn',
    'lawyer': 'Greiðsluþóknun lögmanns',
}

class Settlement extends React.Component {
    constructor(props) {
        super(props)
        this.state= {
            showCreateLine: false,
            random: null,
            editing: null,
            settlementType: 'benefits_text',
            updatingTexts: false,
        }

        
        this.showCreateLine = this.showCreateLine.bind(this)
        this.createLine = this.createLine.bind(this)
        this.saveLine = this.saveLine.bind(this)
        this.cancel = this.cancel.bind(this)
        this.cancelEdit = this.cancelEdit.bind(this)
        this.update = this.update.bind(this)
        this.removeLine = this.removeLine.bind(this)
        this.editLine = this.editLine.bind(this)
        this.onSettlementTypeChange = this.onSettlementTypeChange.bind(this)
        this.saveTexts = this.saveTexts.bind(this)
        this.shouldShowEditButtons = this.shouldShowEditButtons.bind(this)

        this.amount = React.createRef()
        this.description = React.createRef()
        this.details = React.createRef()
        this.settlementType = React.createRef()
        this.before = React.createRef()
        this.after = React.createRef()
    }

    update() {
        this.setState({random: Math.random()})
    }

    showCreateLine() {
        this.setState({showCreateLine: true})
    }
    
    
    createLine() {
        
        let settlementData = {
            description: this.description.current.value,
            amount: 0,
            details: "",
            settlement_type: this.settlementType.current.value,
        }

        if ( this.settlementType.current && this.settlementType.current.value !== 'benefits_text') {
            let amount = this.amount.current.value
            settlementData.amount = amount
            settlementData.details = this.details.current.value
        }
        
        Promise.resolve(this.props.dispatch(createSettlement(this.props.applications.application.id, settlementData)))
        .then(()=> this.setState({showCreateLine: false, editing: null, settlementType: 'benefits_text'}))
    }

    saveLine(s) {
        
        let settlementData = {
            description: this.description.current.value,
            amount: 0,
            details: "",
            id: s.id,
        }
        if ( s.settlement_type !== 'benefits_text') {
            let amount = this.amount.current.value
            settlementData.amount = amount
            settlementData.details = this.details.current.value
        }
        Promise.resolve(this.props.dispatch(createSettlement(this.props.applications.application.id, settlementData)))
        .then(()=> this.setState({showCreateLine: false, editing: null, settlementType: 'benefits_text'}))
    }

    saveTexts() {
        this.setState({updatingTexts: true})
        Promise.resolve(this.props.dispatch(updateApplication({'before_text': this.before.current.value, 'after_text': this.after.current.value}, this.props.applicationId)))
        .then(() => this.setState({updatingTexts: false}))
    }

    removeLine(id) {
        this.props.dispatch(deleteSettlement(id))
    }

    editLine(id) {
        this.setState({editing: id})
    }

    cancelEdit() {
        this.setState({editing: null})
    }

    cancel() {
        this.setState({showCreateLine: false})
    }

    onSettlementTypeChange(e) {
        let value = e.target.value
        this.setState({settlementType: this.settlementType.current.value}, () => {
            if (value === 'lawyer') {
                this.description.current.value = 'Viðbótargreiðslur tryggingarfélags til lögmanns';
                this.details.current.value = 'Greiðist af tryggingarfélagi og hefur ekki áhrif á greiðslur til tjónþola.'
            }
        })
    }

    // componentDidUpdate(prevProps) {

    //     if (Object.keys(this.props.applications.application).length > 0 && Object.keys(prevProps.applications.application).length === 0) {
    //         //this.setState({showCreateLine: true})
    //     }
    // }

    shouldShowEditButtons() {
        return ['in_progress', 'estimate_sent', 'claim_sent'].includes(this.props.applications.application.state)
    }

    render() {

        let editingLine = null;
        if (this.state.editing !== null) {
            let _editingLine = this.props.applications.application.settlements.filter(f => f.id === this.state.editing)
            if (_editingLine.length > 0) {
                editingLine = _editingLine[0]
            }
        }
        return (
            <>
                {this.props.applications.application && this.props.applications.application.settlements &&
                    <>
                        { this.props.settlementType === 'demand' &&
                            <>
                                <div className="row">
                                    <div className="col-8 input-group"><textarea className="form-control" id="before" ref={this.before} defaultValue={this.props.applications.application.jsondata.before_text || ''} placeholder="Texti á undan tölum" /></div>
                                    <div className="col-8 input-group"><textarea className="form-control" id="after" ref={this.after} defaultValue={this.props.applications.application.jsondata.after_text || ''}placeholder="Texti á eftir tölum" /></div>
                                </div>
                                {this.shouldShowEditButtons() && <div className="row pn-buttons my-4">
                                    <div className="col">
                                        <button className="btn-small m-auto" onClick={() => this.saveTexts()}>Vista texta</button>
                                    </div>
                                </div>}
                            </>
                        }

                        <div className="row table-row mt-5">
                            <div className="col-4"><label>Lýsing</label></div>
                            <div className="col-4"><label>Skýring</label></div>
                            <div className="col-2 text-right"><label>Upphæð</label></div>
                            <div className="col-3"><label>Tegund</label></div>
                            {this.shouldShowEditButtons() && <div className="col-3"></div>}
                        </div>
                        <hr className="my-2"/>
                        {this.props.applications.application.settlements.map(s =>  {
                            return (
                                <div>
                                    { this.state.editing===s.id ?
                                        <>
                                            <div className="row table-row">
                                                <div className={s.settlementType === 'benefits_text' ? "col-10 input-group" : "col-4 input-group"}><input className="form-control" name="description" defaultValue={s.description} ref={this.description} /></div>
                                                { ['benefits', 'lawyer'].includes(s.settlement_type) &&
                                                    <>
                                                        <div className="col-4 input-group"><input className="form-control" name="details" defaultValue={s.details} ref={this.details} /></div>
                                                        <div className="col-2 input-group"><input className="form-control" name="amount" defaultValue={s.amount} ref={this.amount} /></div>
                                                    </>
                                                }
                                                <div className="col-3 input-group">
                                                    {settlementTypeTexts[s.settlement_type]}
                                                </div>
                                                <div className="col-3 pn-buttons my-0 justify-content-end">
                                                    <button className="btn-small mr-1" onClick={() => this.saveLine(s)}>Vista</button>
                                                    <button className="btn-small" onClick={this.cancelEdit}>Hætta við</button>
                                                </div>
                                            </div>
                                        </>
                                        
                                        :
                                        <>
                                            <div className="row table-row">
                                                <div className="col-4 input-group">{s.description}</div>
                                                <div className="col-4 input-group">{s.details}</div>
                                                <div className="col-2 input-group justify-content-end">{s.amount > 0 ? formatMoney(s.amount, 0) : ''}</div>
                                                <div className="col-3 input-group">{settlementTypeTexts[s.settlement_type]}</div>
                                                {this.shouldShowEditButtons() && <div className="col-3 pn-buttons my-0 justify-content-end">
                                                    <button className="btn-small mr-1" onClick={() => this.editLine(s.id)}>Breyta</button>
                                                    <button className="btn-small" onClick={() => this.removeLine(s.id)}>Fjarlægja</button>
                                                </div>}
                                            </div>
                                        </>
                                    }
                                    
                                </div>
                            )
                        })}
                        { this.state.showCreateLine ?
                            <>
                                <div className="row table-row">
                                    <div className={this.state.settlementType === 'benefits_text' ? "col-10 input-group" : "col-4 input-group"}><input className="form-control" name="description" ref={this.description} /></div>
                                    { this.state.settlementType !== 'benefits_text' &&

                                        <>
                                            <div className="col-4 input-group"><input className="form-control" name="details" ref={this.details} /></div>
                                            <div className="col-2 input-group"><input className="form-control" name="amount" ref={this.amount} /></div>
                                        </>
                                    }
                                    <div className="col-3 input-group">
                                        <select className="form-control" name="settlement_type" ref={this.settlementType} onChange={this.onSettlementTypeChange}>
                                            <option value="benefits_text">Fyrirsögn</option>
                                            <option value="benefits">Bætur</option>
                                            <option value="lawyer">Greiðsluþóknun lögmannns</option>
                                        </select>
                                    </div>
                                    <div className="col-3 pn-buttons my-0 justify-content-end">
                                        <button className="btn-small mr-1" onClick={() => this.createLine()}>Vista</button>
                                        <button className="btn-small" onClick={this.cancel}>Hætta við</button>
                                    </div>
                                    <div className="col-12">* ATH VSK reiknast sjálfkrafa ofan á greiðsluþóknun lögmanns. Sláið inn upphæð án VSK.</div>
                                </div>
                            </>
                            :
                            <div className="pn-buttons my-0">
                                {this.shouldShowEditButtons() && <button className="btn-small" onClick={this.showCreateLine}>Ný lína</button> }
                            </div>
                        }
                        
                        <div className="pn-buttons my-5 justify-content-center">
                            <button onClick={this.update}>Forskoða PDF</button>
                        </div>
                        { this.state.random !== null &&
                        <Iframe url={`/api/v1/application/${this.props.applications.application.id}/${this.props.settlementType}/?rand=${this.state.random}`}
                            width="100%"
                            height="600px"
                            id="isign-gateway"
                            className="myClassname"
                            display="initial"
                            position="relative"/>
                        }
                    </>
                }
            </>
        );
    }
}

Settlement.defaultProps = {
    settlementType: 'settlement'
}

function mapStateToProps(state) {

    var f = {
        applications: {},
    }
    f.applications = (state.applications === undefined) ? {} : state.applications

    return f;
}

export default connect(mapStateToProps)(Settlement);