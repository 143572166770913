import React from "react";

class TrafficAccidentQuestions extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { infoCollection } = this.props;

        return (
            <fieldset disabled={this.props.isSuperUser == 'False'} className="questions-container">
                <div className="question-container w-100 d-flex align-items-center radio mb-5 flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                    <div className="title">Varstu farþegi eða ökumaður bíls?</div>
                    <input className="ml-auto mr-2" type="radio" id="traffic_accident_in_car_yes" name="traffic_accident_in_car" checked={infoCollection.traffic_accident_in_car === true} value={true}/>
                    <label className="mr-4 mb-0" htmlFor="traffic_accident_in_car_yes">Já</label>
                    <input className="mr-2" type="radio" id="traffic_accident_in_car_no" name="traffic_accident_in_car" checked={infoCollection.traffic_accident_in_car === false} value={false}/>
                    <label className="mb-0" htmlFor="traffic_accident_in_car_no">Nei</label>
                </div>
                <div className="question-container w-100 d-flex align-items-center radio mb-5 flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                    <div className="title" >Varstu gangandi eða hjólandi vegfarandi?</div>
                    <input className="ml-auto mr-2" type="radio" id="traffic_accident_bike_feet_yes" name="traffic_accident_bike_feet" checked={infoCollection.traffic_accident_bike_feet === true} value={true}/>
                    <label className="mr-4 mb-0" htmlFor="traffic_accident_bike_feet_yes">Já</label>
                    <input className="mr-2" type="radio" id="traffic_accident_bike_feet_no" name="traffic_accident_bike_feet" checked={infoCollection.traffic_accident_bike_feet === false} value={false}/>
                    <label className="mb-0" htmlFor="traffic_accident_bike_feet_no">Nei</label>
                </div>
                {infoCollection.traffic_accident_in_car && 
                    <div className="question-container w-100 d-flex align-items-center radio mb-5 flex-wrap">
                        <label htmlFor="traffic_accident_in_car_registration_number">Til hvaða embættis lögrelunnar var brotið kært?</label>
                        <input className="mb-3" type="text" id="traffic_accident_in_car_registration_number" value={infoCollection.traffic_accident_in_car_registration_number} onChange={(e) => this.props.onChange(e)}></input>

                        <div className="w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                            <div className="title">Voru fleiri en einn bíll í árekstrinum?</div>
                            <input className="ml-auto mr-2" type="radio" id="traffic_accident_in_car_multiple_cars_yes" name="traffic_accident_in_car_multiple_cars" checked={infoCollection.traffic_accident_in_car_multiple_cars === true} value={true}/>
                            <label className="mr-4 mb-0" htmlFor="traffic_accident_in_car_multiple_cars_yes">Já</label>
                            <input className="mr-2" type="radio" id="traffic_accident_in_car_multiple_cars_no" name="traffic_accident_in_car_multiple_cars" checked={infoCollection.traffic_accident_in_car_multiple_cars === false} value={false}/>
                            <label className="mb-0" htmlFor="traffic_accident_in_car_multiple_cars_no">Nei</label>
                        </div>
                        {infoCollection.traffic_accident_in_car_multiple_cars &&
                            <div className="w-100 d-flex align-items-center radio flex-wrap">
                                <label htmlFor="traffic_accident_in_car_multiple_cars_registration_number">Veistu skráningarnúmer annarra ökutækja?</label>
                                <input className="mb-3" type="text" id="traffic_accident_in_car_multiple_cars_registration_number" value={infoCollection.traffic_accident_in_car_multiple_cars_registration_number} onChange={(e) => this.props.onChange(e)}></input>
                            </div>
                        }

                        <div className="w-100 d-flex align-items-center radio flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                            <div className="title">Var Akstur og öryggi kallað til?</div>
                            <input className="ml-auto mr-2" type="radio" id="traffic_accident_in_car_akstur_oryggi_yes" name="traffic_accident_in_car_akstur_oryggi" checked={infoCollection.traffic_accident_in_car_akstur_oryggi === true} value={true}/>
                            <label className="mr-4 mb-0" htmlFor="traffic_accident_in_car_akstur_oryggi_yes">Já</label>
                            <input className="mr-2" type="radio" id="traffic_accident_in_car_akstur_oryggi_no" name="traffic_accident_in_car_akstur_oryggi" checked={infoCollection.traffic_accident_in_car_akstur_oryggi === false} value={false}/>
                            <label className="mb-0" htmlFor="traffic_accident_in_car_akstur_oryggi_no">Nei</label>
                        </div>
                    </div>
                    
                }
            </fieldset>
        )
    }
}

export default TrafficAccidentQuestions;
