import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const healthcareProviderTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'HEALTHCARE_PROVIDER');

export const requestHealthcareProviders = () => {
  return {
      type: healthcareProviderTypes.REQUEST,
  };
};

export const receiveHealthcareProviders = (response, query = {}) => {
  return {
      type: healthcareProviderTypes.RECEIVE,
      query: query,
      data: response,
  };
};

export const receiveHealthcareProvidersError = () => {
  return {
      type: healthcareProviderTypes.ERROR,
  };
};

export const fetchHealthcareProviders = () => {
  return dispatch => {
      dispatch(requestHealthcareProviders());

      let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',

      }

      return fetch(`/api/v1/healthcareproviders/`, {
          headers: headers
      })
          .then(parseJSON)
          .then(response => {
              try {
                  dispatch(receiveHealthcareProviders(response));
              } catch (e) {
                  dispatch(receiveHealthcareProvidersError(response))
              }


          })
          .catch(handleNetworkError);
  }
}