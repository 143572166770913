import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const infocollectionTypes = createTypes(['SET_KEY', 'REMOVE_KEY'], 'INFO_COLLECTION');


export const setInfoKey = (key, value) => {
    return {
        type: infocollectionTypes.SET_KEY,
        key: key,
        value: value,
    };
};


export const removeInfoKey = (key) => {
    return {
        type: infocollectionTypes.REMOVE_KEY,
        key: key,
    };
};