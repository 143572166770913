
import moment from "moment";
import React from "react";
import Iframe from "react-iframe";
import { connect } from 'react-redux'
import { fetchApplication, updateApplication } from '../../api/applications/actions'
import { SvgDocument } from "../svg";




class LogItemEmail extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
        }

        this.onClick = this.onClick.bind(this)
        this.onReset = this.onReset.bind(this)

    }

    onClick(logItem, i) {
        var iframe = document.getElementById(`${logItem.email.mandrill_id}`);
        iframe.src = `/attachment/${logItem.email.id}/${i}/`;
        $(`a#email_${logItem.email.mandrill_id}`).show();
    }

    onReset(logItem) {
        var iframe = document.getElementById(`${logItem.email.mandrill_id}`);
        iframe.src = `${logItem.email.url}`;
        $(`a#email_${logItem.email.mandrill_id}`).hide();
    }

    render() {
        const { logItem, superuser } = this.props

        return (
            <div className="card email">
                <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target={`#collapselogitem${logItem.id}`} aria-expanded="true" aria-controls={`collapselogitem${logItem.id}`}>
                            Tölvupóstur {logItem.email.subject} <span>{moment(logItem.email.timestamp).format("D.M.YYYY")} kl. {moment(logItem.email.meta.ts*1000).format("HH:mm:ss")}</span>
                        </button>
                    </h2>

                </div>
                {superuser &&
                    <div id={`collapselogitem${logItem.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">

                            <div className="row">
                                <div className="col-4"><strong>Sendandi</strong></div>
                                <div className="col-12">{logItem.email.sender}</div>
                            </div>
                            <div className="row">
                                <div className="col-4"><strong>Viðtakandi</strong></div>
                                <div className="col-12">{logItem.email.email}</div>
                            </div>
                            <div className="row">
                                <div className="col-4"><strong>Sent</strong></div>
                                <div className="col-12">{moment(logItem.email.timestamp).format("D.M.YYYY")} kl. {moment(logItem.email.meta.ts*1000).format("HH:mm:ss")}</div>
                            </div>
                            <div className="row">
                                <div className="col-4"><strong>Viðfangsefni</strong></div>
                                <div className="col-12">{logItem.email.subject}</div>
                            </div>
                            {logItem.email.meta.attachments && logItem.email.meta.attachments.length > 0 &&
                                <div className="row">
                                    <div className="col-4"><strong>Viðhengi</strong></div>
                                    <div className="col-12">
                                        {logItem.email.meta.attachments.map((a, i) => {
                                            return (
                                                <>
                                                    <a href="javascript:void(0);" onClick={() => this.onClick(logItem, i)} className="mr-2"><SvgDocument /> {a.name}</a>
                                                </>
                                            )
                                        })}
                                        <a id={`email_${logItem.email.mandrill_id}`} href="javascript:void(0);" style={{ display: 'none' }} onClick={() => this.onReset(logItem)}>Sýna tölvupóst</a>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="col">
                            <iframe id={`${logItem.email.mandrill_id}`} src={logItem.email.url} ></iframe>
                        </div>
                    </div>
                }
            </div>


        )
    }

}

LogItemEmail.defaultProps = {}


export default LogItemEmail;