import React from "react";
import { getStepComponent } from '../../utils'


class StepRenderer extends React.Component {
    constructor(props) {
        super(props)
    }

    
    render() {
        let { stepper, index, context, status,renderAllSteps } = this.props
        if (renderAllSteps) {
            return stepper.map((stepName, i) => {
                if (stepName !== 'terms') {
                    let StepComponent = getStepComponent(stepper[i], context, status)
                    return (<StepComponent {...this.props} />)
                }
            })
        } else {
            let StepComponent = getStepComponent(stepper[index], context, status)
            return (<StepComponent {...this.props} />)
        }
    }
}

StepRenderer.defaultProps = {
    context: 'application-process'
}

export default StepRenderer;
