import React from "react";

class WorkAccidentQuestions extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { infoCollection } = this.props;
        return (
            <>
                <fieldset disabled={this.props.isSuperUser == 'False'}>
                    <div className="question-container w-100 d-flex align-items-center radio mb-5 flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                        <div className="title">Var vinnueftirlitið kallað til eða slysið tilkynnt þangað?</div>
                        <input className="ml-auto mr-2" type="radio" id="yes" name="work_accident" checked={infoCollection.work_accident === true} value={true}/>
                        <label className="mr-4 mb-0" htmlFor="yes">Já</label>
                        <input className="mr-2" type="radio" id="no" name="work_accident" checked={infoCollection.work_accident === false} value={false}/>
                        <label className="mb-0" htmlFor="no">Nei</label>
                    </div>
                </fieldset>
                <div className="w-100 mb-3">
                    <label htmlFor="accident_injuries">Hver er vinnuveitandi þinn?</label>
                    <input {...this.props.readOnly} type="text" id="work_accident_employer" value={infoCollection.work_accident_employer} onChange={(e) => this.props.onChange(e)}></input>
                </div>
            </>
        )
    }
}

export default WorkAccidentQuestions;
