import React from "react";
import { connect } from 'react-redux'
import { initStepper, setStep } from '../../api/step-renderer/actions'
import { APPLICATION_PROCESS_STEPS } from '../../constants'
import StepRenderer from '../../components/step-renderer'
import { fetchInsuranceProvider } from "../../api/insurance-provider/actions";
import { fetchAccidentTypes } from "../../api/accident-type/actions";
import { fetchHealthcareProviders } from "../../api/healthcare-provider/actions";
import { fetchProfile } from "../../api/profile/actions";
import { createApplication, updateApplication, fetchApplication } from '../../api/applications/actions'
import { setInfoKey } from '../../api/information-collection/actions'
import { step1validated, step2validated, step3validated, termsvalidated } from '../../utils'
import BreadCrumbs from "../../components/breadcrumbs";
import ApplicationActions from "../../components/application-actions";
import { POSTAL_CODES } from '../../constants'

class Application extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            profile : this.props.profile.results,
            accident_type : '',
            files: [],
            status : this.props.applicationId ? 'update' : 'create',
        }
        this.previous = this.previous.bind(this)
        this.next = this.next.bind(this)
        this.submit = this.submit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.signingCompleted = this.signingCompleted.bind(this)
        this.shouldShowForwardButton = this.shouldShowForwardButton.bind(this)
        this.isEditable = this.isEditable.bind(this)

        this.timer = null
    }

    componentDidMount() {
        this.props.dispatch(initStepper(APPLICATION_PROCESS_STEPS))
        this.props.dispatch(fetchInsuranceProvider())
        this.props.dispatch(fetchAccidentTypes())
        this.props.dispatch(fetchHealthcareProviders())
        this.props.dispatch(fetchProfile(this.props.applicantId))
        if (this.props.applicationId){
            this.props.dispatch(fetchApplication(this.props.shouldSetInfoKeys, this.props.applicationId))
        }
    }

    componentDidUpdate(prevProps){
        if (JSON.stringify(this.props.profile) !== JSON.stringify(prevProps.profile)) {
            this.setState({
                profile: this.props.profile.results
            })
        }
 
        if (JSON.stringify(this.props.applications.results) !== JSON.stringify(prevProps.applications.results) && this.props.applications.results.commission || (this.props.applications.application.length == prevProps.applications.application.length && JSON.stringify(this.props.applications.application.updated) !== JSON.stringify(prevProps.applications.application.updated))){
            if (this.props.stepRenderer.stepper[this.props.stepRenderer.index] === 'step3') {
                this.next()
            }
        }

        if ( this.timer === null && this.props.applications && this.props.applications.application && this.props.stepRenderer.stepper[this.props.stepRenderer.index] === 'final' && this.state.status !== 'update') {
            this.timer = setInterval(() => this.props.dispatch(fetchApplication(false, this.props.applications.results.id)), 5000)
        }
    }

    shouldShowForwardButton(step) {
        switch (step) {
            case 'step1':
                return step1validated(this.state.profile)
            case 'step2':
                return step2validated(this.props)
            case 'step3':
                return step3validated(this.props)
            case 'terms':
                return termsvalidated(this.props)
            default:
                return false
        }
    }

    isEditable() {
        return this.state.status === 'create' || (this.props.applications.application.state == 'waiting' && this.props.superuser)
    }

    previous() {
        this.props.dispatch(setStep(this.props.stepRenderer.index - 1))
    }

    next() {
        this.props.dispatch(setStep(this.props.stepRenderer.index + 1))
        if (!this.props.superuser) {
            window && window.scroll(0,0)
        }
    }

    submit() {
        let applicationObj = this.state;

        Object.keys(applicationObj).map((item) => {
            if ((item == 'accident_type' || item =='insurance_provider' || item == 'healthcare_provider') && applicationObj[item] !== 'other') {
                    delete applicationObj[`${item}_other`]
                }
        })

        let profile = {
            email: this.state.profile.email,
            postal_code: this.state.profile.postal_code,
            city: this.state.profile.city,
            address: this.state.profile.address,
        }

        let postData = Object.assign({}, this.props.infoCollection)
        postData['profile'] = profile

        if (this.props.applicationId) {
            Promise.resolve(this.props.dispatch(updateApplication(postData, this.props.applicationId)))
            .then(() => {
                window.location = '/minarsidur/'
            })
        } else {
            this.props.dispatch(createApplication(postData))
        }
    }
    
    onChange(value, id, type){
        console.log(value, id, type)
        if (type === 'profile') {
    
            if(id === 'postal_code'){
                if(POSTAL_CODES.hasOwnProperty(value.toString())){
                    this.setState({ profile: { ...this.state.profile, [id]: value, 'city': POSTAL_CODES[value.toString()]} });
                }else{
                    this.setState({ profile: { ...this.state.profile, [id]: value, 'city': ''} });
                }
            }else{
                this.setState({ profile: { ...this.state.profile, [id]: value} });
            }
        } else if (type == 'files'){
            this.setState({files: value})
            this.props.dispatch(setInfoKey(id, value))
        } else {
            this.props.dispatch(setInfoKey(id, value))
        }

    }

    renderNextOrFinishButton(index, stepper, application){
        if (this.props.superuser) {
            if (application.state === 'waiting') {
                return <button className="next" onClick={() => this.submit()} disabled={!this.shouldShowForwardButton(stepper[index])}>Vista</button>
            }
            else {
                return <></>
            }
        }
        if (!this.isEditable()) {
            return <></>
        }
        if (stepper[index] == 'final' && this.signingCompleted(application) ) {
            return <a className="final-button" href="/minarsidur/">{this.props.superuser == 'True' ? 'Mínar síður' : 'Mínar umsóknir'}</a> 
        } else if (stepper[index] === 'step3')  {
            return <button className="next" disabled={ this.props.applications.isFetcing } onClick={() => this.submit()} disabled={!this.shouldShowForwardButton(stepper[index])}>{ this.props.applications.isFetcing? 'Augnablik...' : 'Áfram' }</button>
        } else{
            return <button className="next" onClick={() => this.next(stepper[index])} disabled={!this.shouldShowForwardButton(stepper[index])}>Áfram</button>
        }
    }

    signingCompleted(application){
        if (this.props.infoCollection.alternative_applicant_ssn && this.props.infoCollection.alternative_applicant_ssn !== '') {
            return true
        }
        let isCompleted = false
        if (application && application.commission && application.commission.status == 'commission_signed'){
            isCompleted = true
        } 
        return isCompleted;
    }

    render() {

        let { stepper, index } = this.props.stepRenderer
        let { healthcareProvider, insuranceProvider, accidentType, applications } = this.props

        if(this.signingCompleted(applications.application)) {
            clearInterval(this.timer)
        }

        if (stepper && this.props.superuser) {
            index = stepper.length - 2
        }

        return (
            <>
                {stepper && (Object.keys(applications.application).length > 0 ||  this.state.status === 'create') &&
                    <div>
                        { !this.props.renderAllSteps &&
                            <BreadCrumbs store={this.props.store} /> 
                        }
                        
                        <StepRenderer 
                            context="application-process" 
                            index={index} stepper={stepper} renderAllSteps={this.props.superuser || this.props.renderAllSteps}
                            editable={ this.isEditable() }
                            healthcareProvider={healthcareProvider} 
                            insuranceProvider={insuranceProvider} 
                            accidentType={accidentType} 
                            profile={this.state.profile} 
                            onChange={this.onChange} 
                            accident_type={this.state.accident_type} 
                            infoCollection={this.props.infoCollection} 
                            files={this.state.files} 
                            applications={this.props.applications}
                            applicantId={this.props.applicantId}
                            applicationId={this.props.applicationId}
                            isSuperUser={this.props.superuser}
                            shouldSetInfoKeys={this.props.shouldSetInfoKeys}
                            status={this.state.status}
                            terms={this.props.terms} 
                            signingCompleted={ this.signingCompleted(applications.application)}
                            />

                        <div className="pn-buttons">
                            {this.props.superuser ?
                                <button className="previous" onClick={() => window.location = '/minarsidur/'}></button>
                            :
                                <>{ !this.signingCompleted(applications.application) &&
                                    <button className={index === 0 ? "disabled previous" : "previous"} disabled={index <= 0 } onClick={() => this.previous()}>{ index !== 0 ? 'Til baka' : '' }</button>
                                }</>
                            }
                            {this.renderNextOrFinishButton(index, stepper, applications.application)}                            
                        </div>
                    </div>

                }
            </>
        );
    }
}

function mapStateToProps(state) {

    var f = {
        stepRenderer: {},
        healthcareProvider: {},
        insuranceProvider: {},
        accidentType: {},
        profile : {},
        infoCollection: {},
        applications: {},
    }
    f.stepRenderer = (state.stepRenderer === undefined) ? {} : state.stepRenderer
    f.healthcareProvider = (state.healthcareProvider === undefined) ? {} : state.healthcareProvider
    f.insuranceProvider = (state.insuranceProvider === undefined) ? {} : state.insuranceProvider
    f.accidentType = (state.accidentType === undefined) ? {} : state.accidentType
    f.profile = (state.profile === undefined) ? {} : state.profile
    f.infoCollection = (state.infoCollection === undefined) ? {} : state.infoCollection
    f.applications = (state.applications === undefined) ? {} : state.applications

    return f;
}

export default connect(mapStateToProps)(Application);