import React from "react";
import { connect } from 'react-redux'
import Iframe from 'react-iframe'

class FinalStep extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {

    return (
      <>
        { !this.props.signingCompleted ?
          <Iframe url={this.props.applications.results.commission.signing_url}
            width="100%"
            height="600px"
            id="isign-gateway"
            className="myClassname"
            display="initial"
            position="relative" />
          :
          <>
            <h2>Umsókn móttekin</h2>
            <p>Þú munt fá tölvupóst um framvindu málsins á næstunni.</p>
            { (this.props.infoCollection.alternative_applicant_ssn && this.props.infoCollection.alternative_applicant_ssn !== '') &&
              <p>Þar sem umsækjandi er ekki sami og tjónþoli mun starfsmaður lögmannstofunnar hafa samband til þess að undirrita umboð.</p>
            }
          </>
        }
      </>
    )
  }
}

export default FinalStep;
