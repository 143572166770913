import React from "react";
import DatePicker from 'react-date-picker';

class MedicalMistakeQuestions extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { infoCollection, disabled } = this.props;

        return (
            <fieldset disabled={this.props.isSuperUser == 'False'} className="questions-container">
                <label htmlFor="medical_mistake_where">Hvar átti tjónsatvikið sér stað? (Nafn sjúkrahúss og deild, nafn heilsugæslustöðvar, einkarekinnar læknastofu eða annarrar heilbrigðisstofnunar)</label>
                <input className="mb-3" type="text" id="medical_mistake_where" value={infoCollection.medical_mistake_where} onChange={(e) => this.props.onChange(e)}></input>

                <label>Dagsetning og dvalartími á stofnun þar sem tjónsatvik átti sér stað</label>
                <label htmlFor="medical_mistake_hospital_stay_start">Frá:</label>
                <DatePicker
                    onChange={(value) => this.props.onDateChange(value, "medical_mistake_hospital_stay_start")}
                    value={infoCollection.medical_mistake_hospital_stay_start ? infoCollection.medical_mistake_hospital_stay_start : new Date()}
                    disabled={this.props.isSuperUser == 'False'}
                    className="mb-3" />
                <label htmlFor="medical_mistake_hospital_stay_end">Til:</label>
                <DatePicker
                    onChange={(value) => this.props.onDateChange(value, "medical_mistake_hospital_stay_end")}
                    value={infoCollection.medical_mistake_hospital_stay_end ? infoCollection.medical_mistake_hospital_stay_end : new Date()} 
                    disabled={this.props.isSuperUser == 'False'}
                    className="mb-3" />
                <div className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                    <div className="title">Hefur umsækjandi verið til meðferðar vegna heilsutjónsins? (Til dæmis á sjúkrahúsum, hjá sérfræðilæknum, tannlæknum, sjúkraþjálfurum o.fl.)</div>
                    <input {...disabled} className="ml-auto mr-2" type="radio" id="medical_mistake_treatment_after_incident_yes" name="medical_mistake_treatment_after_incident" checked={infoCollection.medical_mistake_treatment_after_incident === true} value={true}/>
                    <label className="mr-4 mb-0" htmlFor="medical_mistake_treatment_after_incident_yes">Já</label>
                    <input {...disabled} className="mr-2" type="radio" id="medical_mistake_treatment_after_incident_no" name="medical_mistake_treatment_after_incident" checked={infoCollection.medical_mistake_treatment_after_incident === false} value={false}/>
                    <label className="mb-0" htmlFor="medical_mistake_treatment_after_incident_no">Nei</label>
                </div>
                <div className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                    <div className="title">Er umsækjandi enn að glíma við afleiðingar tjónsatviksins að stríða?</div>
                    <input className="ml-auto mr-2" type="radio" id="medical_mistake_consequences_yes" name="medical_mistake_consequences" checked={infoCollection.medical_mistake_consequences === true} value={true}/>
                    <label className="mr-4 mb-0" htmlFor="medical_mistake_consequences_yes">Já</label>
                    <input className="mr-2" type="radio" id="medical_mistake_consequences_no" name="medical_mistake_consequences" checked={infoCollection.medical_mistake_consequences === false} value={false}/>
                    <label className="mb-0" htmlFor="medical_mistake_consequences_no">Nei</label>
                {infoCollection.medical_mistake_consequences && 
                    <div className="w-100">
                        <label className="mb-1" htmlFor="medical_mistake_consequences_description">Lýsið þeim afleiðingum.</label>
                        <textarea className="mb-0 w-100" type="text" id="medical_mistake_consequences_description" value={infoCollection.medical_mistake_consequences_description} onChange={(e) => this.props.onChange(e)}></textarea>
                    </div>
                }
                </div>

                <div className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                    <div className="title">Er umsækjandi enn til meðferðar vegna þessara afleiðinga?</div>
                    <input className="ml-auto mr-2" type="radio" id="medical_mistake_consequences_still_yes" name="medical_mistake_consequences_still" checked={infoCollection.medical_mistake_consequences_still === true} value={true}/>
                    <label className="mr-4 mb-0" htmlFor="medical_mistake_consequences_still_yes">Já</label>
                    <input className="mr-2" type="radio" id="medical_mistake_consequences_still_no" name="medical_mistake_consequences_still" checked={infoCollection.medical_mistake_consequences_still === false} value={false}/>
                    <label className="mb-0" htmlFor="medical_mistake_consequences_still_no">Nei</label>
                {infoCollection.medical_mistake_consequences_still && 
                    <div className="w-100">
                        <label className="mb-1" htmlFor="medical_mistake_consequences_still_where">Tilgreinið hvar.</label>
                        <textarea classname="w-100" type="text" id="medical_mistake_consequences_still_where" value={infoCollection.medical_mistake_consequences_still_where} onChange={(e) => this.props.onChange(e)}></textarea>
                    </div>
                }
                </div>

                <div className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                    <div className="title">Hefur tjónsatvikið valdið umsækjanda tekjutapi?</div>
                    <input className="ml-auto mr-2" type="radio" id="medical_mistake_income_loss_yes" name="medical_mistake_income_loss" checked={infoCollection.medical_mistake_income_loss === true} value={true}/>
                    <label className="mr-4 mb-0" htmlFor="medical_mistake_income_loss_yes">Já</label>
                    <input className="mr-2" type="radio" id="medical_mistake_income_loss_no" name="medical_mistake_income_loss" checked={infoCollection.medical_mistake_income_loss === false} value={false}/>
                    <label className="mb-0" htmlFor="medical_mistake_income_loss_no">Nei</label>
                    {infoCollection.medical_mistake_income_loss && 
                        <div className="w-100">
                            <label>Tilgreinið tímabil óvinnufærni.</label>
                            <label htmlFor="medical_mistake_income_loss_start">Frá:</label>
                            <DatePicker
                                onChange={(value) => this.props.onDateChange(value, "medical_mistake_income_loss_start")}
                                value={infoCollection.medical_mistake_income_loss_start ? infoCollection.medical_mistake_income_loss_start : new Date()} 
                                disabled={this.props.isSuperUser == 'False'}/>
                            <label htmlFor="medical_mistake_income_loss_end">Til:</label>
                            <DatePicker
                                onChange={(value) => this.props.onDateChange(value, "medical_mistake_income_loss_end")}
                                value={infoCollection.medical_mistake_income_loss_end ? infoCollection.medical_mistake_income_loss_end : new Date()} 
                                disabled={this.props.isSuperUser == 'False'}
                                />
                        </div>
                    }
                </div>

                <div className="question-container w-100 d-flex align-items-center radio mb-3 flex-wrap" onChange={(e) => this.props.onRadioChange(e)}>
                    <div className="title">Hefur umsækjandi orðið fyrir öðrum útgjöldum vegna afleiðinga tjónsatviksins? (Hér með talinn kostnaður vegna lyfja, meðferðar, ferðakostnaður o.fl.)</div>
                    <input className="ml-auto mr-2" type="radio" id="medical_mistake_expenses_yes" name="medical_mistake_expenses" checked={infoCollection.medical_mistake_expenses === true} value={true}/>
                    <label className="mr-4 mb-0" htmlFor="medical_mistake_expenses_yes">Já</label>
                    <input className="mr-2" type="radio" id="medical_mistake_expenses_no" name="medical_mistake_expenses" checked={infoCollection.medical_mistake_expenses === false} value={false}/>
                    <label className="mb-0" htmlFor="medical_mistake_expenses_no">Nei</label>
                    {infoCollection.medical_mistake_expenses && 
                        <div className="w-100">
                            <label htmlFor="medical_mistake_expenses_amount">Tilgreinið fjárhæð.</label>
                            <input className="mb-0" type="text" id="medical_mistake_expenses_amount" value={infoCollection.medical_mistake_expenses_amount} onChange={(e) => this.props.onChange(e)}></input>
                        </div>
                    }
                </div>
            </fieldset>
        )
    }
}

export default MedicalMistakeQuestions;
