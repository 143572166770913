import React from 'react';

import { infocollectionTypes } from './actions'
import {accidentTypes} from '../accident-type/actions'

window.accidentTypeResults = []

const initialState = {
    files: [],
    accident_date: '',
    accident_location: '',
    accident_description: '',
    accident_injuries: '',
    accident_type: '',
    accident_alcohol_medicin: '',
    accident_time: '',
    accident_witnesses: '',
    accident_police: '',
    doctors_date: '',
    doctors_name: '',
    healthcare_provider: '',
    has_home_doctor: '',
    home_doctor_name: '',
    other_caretakers: '',
    unable_to_work: '',
    disabled_after_accident: '',
    injuries_after_accident: '',

    insurance_provider: '',
    insurance_provider_insurance_holder_name: '',
    bank_info_bank: '',
    bank_info_hb: '',
    bank_info_account: '',
}

export function infoCollection(state = initialState, action) {


    switch (action.type) {

        case accidentTypes.RECEIVE:
            state = { ...state }
            window.accidentTypeResults = action.data
            return state

        case infocollectionTypes.SET_KEY:

            state = { ...state }
            state[action.key] = action.value 

            if (action.key == 'accident_type') {
                console.log(window.accidentTypeResults)
                let filtered = accidentTypeResults.filter(f => f.id === parseInt(action.value))
                let verboseValue = ''
                if (filtered.length > 0) {
                    verboseValue = filtered[0].name
                }


                Object.keys(state).map(key => {
                    if(key.includes('criminal_victims') || key.includes('work_accident') || key.includes('traffic_accident') || key.includes('medical_mistake') || key.includes('other_accident')) {
                        delete state[key]
                    }
                })

                if (verboseValue === 'Líkamsárás') {
                    state['criminal_victims_incident_date'] = '';
                    state['criminal_victims_charge'] = ''; 
                } else if (verboseValue === 'Vinnuslys') {
                    state['work_accident'] = '';
                    state['work_accident_employer'] = '';
                } else if (verboseValue === 'Umferðarslys') {
                    state['traffic_accident_in_car'] = '';
                    state['traffic_accident_bike_feet'] = '';
                } else if (verboseValue === 'Læknamistök') {
                    state['medical_mistake_where'] = '';
                    state['medical_mistake_hospital_stay_start'] = '';
                    state['medical_mistake_hospital_stay_end'] = '';
                    state['medical_mistake_treatment_after_incident'] = '';
                    state['medical_mistake_consequences'] = '';
                    state['medical_mistake_consequences_still'] = '';
                    state['medical_mistake_income_loss'] = '';
                    state['medical_mistake_expenses'] = '';
                } else if (verboseValue === 'Annað'){
                    state['other_accident_type_description'] = '';
                }
            }

            if (action.key === 'has_home_doctor') {
                if (action.value === true) {
                    state['home_doctor_name'] = ''
                } else {
                    delete state['home_doctor_name']
                }
            }


            if (action.key === 'accident_witnesses') {
                if (action.value === true) {
                    state['accident_witnesses_list'] = ''
                } else {
                    delete state['accident_witnesses_list']
                }
            }

            if (action.key === 'unable_to_work') {
                if (action.value === true) {
                    state['unable_to_work_start'] = ''
                    state['unable_to_work_end'] = ''
                } else {
                    delete state['unable_to_work_start']
                    delete state['unable_to_work_end']
                }
            }

            if (action.key === 'disabled_after_accident') {
                if (action.value === true) {
                    state['disabled_after_accident_who_how_much'] = ''
                } else {
                    delete state['disabled_after_accident_who_how_much']
                }
            }

            if (action.key === 'injuries_after_accident') {
                if (action.value === true) {
                    state['injuries_after_accident_which_injury_doctor'] = ''
                } else {
                    delete state['injuries_after_accident_which_injury_doctor']
                }
            }

            if (action.key === 'traffic_accident_in_car') {
                if (action.value === true) {
                    state['traffic_accident_in_car_multiple_cars'] = ''
                    state['traffic_accident_in_car_akstur_oryggi'] = ''
                } else {
                    delete state['traffic_accident_in_car_multiple_cars']
                    delete state['traffic_accident_in_car_akstur_oryggi']
                }
            }

            if (action.key === 'traffic_accident_in_car_multiple_cars') {
                if (action.value === true) {
                    state['traffic_accident_in_car_multiple_cars_registration_number'] = ''
                } else {
                    delete state['traffic_accident_in_car_multiple_cars_registration_number']
                }
            }

            if (action.key === 'medical_mistake_consequences') {
                if (action.value === true) {
                    state['medical_mistake_consequences_description'] = ''
                } else {
                    delete state['medical_mistake_consequences_description']
                }
            }

            if (action.key === 'medical_mistake_consequences_still') {
                if (action.value === true) {
                    state['medical_mistake_consequences_still_where'] = ''
                } else {
                    delete state['medical_mistake_consequences_still_where']
                }
            }

            if (action.key === 'medical_mistake_income_loss') {
                if (action.value === true) {
                    state['medical_mistake_income_loss_start'] = ''
                    state['medical_mistake_income_loss_start'] = ''
                } else {
                    delete state['medical_mistake_income_loss_end']
                    delete state['medical_mistake_income_loss_end']
                }
            }

            if (action.key === 'medical_mistake_expenses') {
                if (action.value === true) {
                    state['medical_mistake_expenses_amount'] = ''
                } else {
                    delete state['medical_mistake_expenses_amount']
                }
            }

            if (action.key === 'criminal_victims_charge') {
                if (action.value === true) {
                    state['criminal_victims_charge_date'] = ''
                    state['criminal_victims_charge_where'] = ''
                } else {
                    delete state['criminal_victims_charge_date']
                    delete state['criminal_victims_charge_where']
                }
            }
            

            return state

        case infocollectionTypes.REMOVE_KEY:

            state = { ...state }

            if (action.key !== undefined) {
                delete state[action.key]
            }

        default:
            return state
    }
}