import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError, getCookie } from '../../utils'
import { fetchApplication } from '../applications/actions';
import { setInfoKey } from '../information-collection/actions'
export const createSettlementTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'CREATE_SETTLEMENT');
export const deleteSettlementTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'DELETE_SETTLEMENT');

export const requestCreateSettlement = () => {
  return {
      type: createSettlementTypes.REQUEST,
  };
};

export const receiveCreateSettlement = (response) => {
  return {
      type: createSettlementTypes.RECEIVE,
      data: response,
  };
};

export const receiveCreateSettlementError = () => {
  return {
      type: createSettlementTypes.ERROR,
  };
};

export const createSettlement = (applicationId, settlementData) => {
  return dispatch => {
      dispatch(requestCreateSettlement());
        
      let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
      }

      let method = 'POST';
      let url = `/api/v1/settlements/?application_id=${applicationId}`
      
      if (Object.keys(settlementData).includes('id')) {
          method = 'PATCH';
          url = `/api/v1/settlements/${settlementData.id}/`
      }

      

      fetch(url, {
          headers: headers,
          method: method,
          body: JSON.stringify(settlementData),
          credentials: 'include',
      })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(fetchApplication(false, applicationId))
                dispatch(receiveCreateSettlement(response));
            } catch (e) {
                dispatch(receiveCreateSettlementError(response))
            }
        })
        .catch(handleNetworkError);
  }
}

// export const requestSettlement = () => {
//   return {
//       type: applicationTypes.REQUEST,
//   };
// };

// export const receiveSettlement = (response, query = {}) => {
//   return {
//       type: applicationTypes.RECEIVE,
//       query: query,
//       data: response,
//   };
// };

// export const receiveSettlementError = () => {
//   return {
//       type: applicationTypes.ERROR,
//   };
// };

// export const fetchSettlement = (shouldSetInfoKeys, application_id) => {
//   return dispatch => {
//       dispatch(requestSettlement());
        
//       let headers = {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//       }

//       fetch(`/api/v1/settlement/?application_id=${application_id}/`, {
//           headers: headers,
//           method: 'GET',
//       })
//           .then(parseJSON)
//           .then(response => {
//               try {
//                   Promise.resolve(dispatch(receiveSettlement(response)))
//                   .then(()=> {
//                       if (response.jsondata && shouldSetInfoKeys) {
//                         Object.keys(response.jsondata).map((key) => dispatch(setInfoKey(key,response.jsondata[key])))
//                     }
//                   })
//               } catch (e) {
//                   dispatch(receiveSettlementError(response))
//               }
//           })
//           .catch(handleNetworkError);
//   }
// }


export const requestDeleteSettlement = () => {
    return {
        type: deleteSettlementTypes.REQUEST,
    };
  };
  
  export const receiveDeleteSettlement = (response, settlementId) => {
    return {
        type: deleteSettlementTypes.RECEIVE,
        id: settlementId,
        data: response,
    };
  };
  
  export const receiveDeleteSettlementError = (response, settlementId) => {
    return {
        type: deleteSettlementTypes.ERROR,
        id: settlementId,
        data: response,
    };
  };
  
  export const deleteSettlement = (settlementId) => {
    return dispatch => {
        dispatch(requestDeleteSettlement());
          
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        }
  
        fetch(`/api/v1/settlements/${settlementId}/`, {
            headers: headers,
            method: 'DELETE',
            credentials: 'include',
        })
        .then(response => {
            try {
                dispatch(receiveDeleteSettlement(response, settlementId));
            } catch (e) {
                dispatch(receiveDeleteSettlementError(response, settlementId))
            }
        })
        .catch(handleNetworkError);
    }
  }
  
  