import React from "react";
import FileUploader from '../file-uploader'
import Select from 'react-select'
import {selectOptions, customStyles} from './select'


class Step3 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      [`insurance_provide_display`]: false,
    }
  }

  onChange(e) {
    const { value, id } = e.currentTarget
    this.setState({
      [`${id}_display`]: value == 'other'
    })

    this.props.onChange(value, id)
  }
  
  onChangeSelect(item, id) {
    let value = item.value

    this.setState({
      [`${id}_display`]: value === 'other'
    })

    this.props.onChange(value, id)
  }

  addFilesToState(files) {
    let oldFiles = this.props.infoCollection.files
    let newFiles = oldFiles.concat(files)
    
    this.props.onChange(newFiles, "files", "files")
  }

  removeImage(file) {
    let newFiles = []
    this.state.files.map((f) => {
      if (f.base64 !== file.base64) {
        newFiles.push(f)
      }
      return newFiles
    })

    this.props.onChange(newFiles, "files", "files")
  }

  render() {
    let { infoCollection } = this.props;

    let insuranceProviderOptions = this.props.insuranceProvider.results.map((a) => {return {value: ""+a.id, label: a.name}})
    insuranceProviderOptions.push({value: 'other', label: 'Annað'})
    let insuranceProviderValue = insuranceProviderOptions.filter(f => f.value === infoCollection.insurance_provider)

    let readOnly = {}
    let disabled = {}
    let selectDisabled = {}
    if (!this.props.editable)  {
        readOnly['readOnly'] = "readonly"
        disabled['disabled'] = "disabled"
        selectDisabled['isDisabled'] = true
    }

    return (
      <fieldset disabled={this.props.isSuperUser == 'False'}>
        <div className="container-fluid step-3 d-flex align-items-center flex-column">
          <div className="step-3-col">
            <p className="semi-bold">Bankaupplýsingar</p>
            <div className="w-100 row bank-info">
              <div className="col-md-4 col-8">
                <label htmlFor="bank_info_bank">Banki</label>
                <input {...readOnly} className="w-100" id="bank_info_bank" value={infoCollection.bank_info_bank} type="number" onChange={(e) => this.onChange(e)}></input>
              </div>
              <div className="col-md-3 col-8">
                <label htmlFor="bank_info_hb">Höfuðbók</label>
                <input {...readOnly} className="w-100" id="bank_info_hb" value={infoCollection.bank_info_hb} type="number" onChange={(e) => this.onChange(e)}></input>
              </div>
              <div className="col-md-6 mt-3 mt-md-0">
                <label htmlFor="bank_info_account">Númer reiknings</label>
                <input {...readOnly} className="w-100" id="bank_info_account" value={infoCollection.bank_info_account} type="number" onChange={(e) => this.onChange(e)}></input>
              </div>
            </div>
            <div className="w-100 insurance">
              <label htmlFor="insurance_provider">Tryggingafélag</label>
              <Select {...selectDisabled} {...selectOptions} id="insurance_provider" options={insuranceProviderOptions} value={insuranceProviderValue} onChange={(e) => this.onChangeSelect(e, "insurance_provider")} />
              {insuranceProviderValue.length > 0 && insuranceProviderValue[0].value === 'other' && <div><label htmlFor="accident_type_other">Hvað?</label><input type="text" id="insurance_provider_other" onChange={(e) => this.onChange(e)} value={infoCollection.insurance_provider_other}></input></div>}
            </div>
            <div className="w-100 mb-3">
              <label htmlFor="insurance_provider_insurance_holder_name">Nafn tryggingataka (Valfrjálst, t.d. ef um fjölskyldutryggingu er að ræða og tryggingataki er ekki sami og tjónþoli)?</label>
              <input {...readOnly} type="text" id="insurance_provider_insurance_holder_name" value={infoCollection.insurance_provider_insurance_holder_name} onChange={(e) => this.onChange(e)}></input>
            </div>
            <div className="files">Viðhengi</div>
            <FileUploader
              removeImage={this.removeImage.bind(this)}
              multiple={true}
              addFilesToState={this.addFilesToState.bind(this)}
              files={this.props.files}
              {...disabled}
            />
            {this.props.applications.application && this.props.applications.application.application_document && this.props.applications.application.application_document.filter(f => f.document_type === 'user_submitted').map(doc => 
              <a target="_blank" className="mr-2 mb-2 btn btn-outline-primary btn-sm" href={doc.url}>
                {doc.title} 
              </a>
            )}
            {this.state.imageErrorMsg ? <div className="error-msg d-block">{this.state.imageErrorMsg}</div> : null}
          </div>
        </div>
      </fieldset>
    )
  }
}

export default Step3;
