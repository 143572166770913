
import React from "react";
import Iframe from "react-iframe";
import { connect } from 'react-redux'
import { fetchApplication, updateApplication } from '../../api/applications/actions'

const actionTransitions = {
    'waiting': [
        {
            newState: 'signing',
            type: 'success',
            text: 'Læsa og hefja undirritun'
        },
        {
            newState: 'denied',
            type: 'danger',
            text: 'Hafna',
            callback: (_this) => {
                window.location = '/minarsidur/'
            }
        }
    ],
    'signing': [
        {
            newState: 'signing',
            type: 'info',
            text: 'Undirrita umsókn', 
            callback: (_this) => {
                _this.setState({showSigning: true})
            },
            condition: (application) => {
                return !application.signed
            }
        },
        {
            newState: 'waiting',
            type: 'warning',
            text: 'Aflæsa umsókn',
            condition: (application) => {
                return !application.signed
            }
        },  
    ],
    'signed': [
        {
            newState: 'in_progress',
            type: 'success',
            text: 'Senda á bótaskyldan aðila',
        },
        {
            newState: 'dismissed',
            type: 'danger',
            text: 'Fella niður'
        },

    ],
    'in_progress': [
        {
            newState: 'dismissed',
            type: 'danger',
            text: 'Fella niður'
        },
        {
            newState: 'archived',
            type: 'danger',
            text: 'Loka máli'
        },
    ],
    'estimate_sent': [
        {
            newState: 'claim_sent',
            type: 'success',
            text: 'Senda kröfubréf',
            condition: (application) => {
                return application.settlement_total > 0 && application.application_document.filter(f => f.document_type == 'estimate').length > 0
            }
        }
    ],
    'claim_sent': [
        {
            newState: 'finished',
            type: 'success',
            text: 'Senda lokauppgjör',
            condition: (application) => {
                return application.settlement_total > 0
            }
        }
    ],
}



class ApplicationActions extends React.Component {

    constructor(props){
        super(props)
        
        this.state = {
            showSigning: false,
        }

        this.buttonAction = this.buttonAction.bind(this)
        this.timer = null

    }

    buttonAction(buttonProps, id) {

        Promise.resolve(this.props.dispatch(updateApplication({state: buttonProps.newState}, id)))
        .then(() => {
            if (buttonProps.callback) {
                buttonProps.callback(this)
            }
        })


    }

    componentDidUpdate() {
        if ( this.timer === null && this.props.applications && this.props.applications.application ) {
            if (this.props.applications.application.state === "signing" && !this.props.applications.application.signed) {
                this.timer = setInterval(() => this.props.dispatch(fetchApplication(false, this.props.applications.application.id)), 5000)
            } else {
                clearInterval(this.timer)
            }
        }
        if (this.props.applications.application.state === "signed" && this.state.showSigning) {
            this.setState({showSigning: false})
        }
    }

    render() {


        let availableActions = []
        if (Object.keys(actionTransitions).includes(this.props.applications.application.state )) {
            availableActions = actionTransitions[this.props.applications.application.state]
        }
        let buttons = availableActions.map(a => {
            if (a.condition && a.condition(this.props.applications.application) || a.condition === undefined) {
                return (
                    <button onClick={() => this.buttonAction(a, this.props.applications.application.id)} className={`mr-3 btn btn-${a.type}`}>{a.text}</button>
                )
            }
        })

        return (
            <>
                { this.props.superuser &&
                    <>
                        <div className={`col application-actions-container pn-buttons justify-content-end pr-0 mt-0 mb-3`}>
                            { buttons }
                        </div>
                        {this.props.applications.application.application_signed && this.state.showSigning &&
                            <div className='signing-iframe-container'>
                                <Iframe url={this.props.applications.application.application_signed.signing_url}
                                    width="100%"
                                    height="600px"
                                    id="isign-gateway"
                                    className="myClassname"
                                    display="initial"
                                    position="relative"/>
                            </div>
                        }
                    </>
                }
            </>
        )
    }

}

ApplicationActions.defaultProps = { }

function mapStateToProps(state) {

    var f = {
        applications: {}
    }
    f.applications = (state.applications === undefined) ? {} : state.applications

    return f;
}

export default connect(mapStateToProps)(ApplicationActions);