import React from "react";
import DatePicker from 'react-date-picker';

class CriminalVictimQuestions extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        let readOnly = {}
        let disabled = {}
        let selectDisabled = {}
        if (!this.props.editable)  {
            readOnly['readOnly'] = "readonly"
            disabled['disabled'] = "disabled"
            selectDisabled['isDisabled'] = true
        }

        let { infoCollection } = this.props
        return (
            <fieldset disabled={this.props.isSuperUser == 'False'} className="questions-container">
                <div className="question-container w-100 d-flex align-items-center radio mb-5 flex-wrap">
                    <label htmlFor="criminal_victims_incident_date">Hvenær var brotið framið (ef það eru meira en tvö ár, þá greiðir bótanefnd ekki bætur)</label>
                    <DatePicker
                        onChange={(value) => this.props.onDateChange(value, "criminal_victims_incident_date")}
                        value={infoCollection.criminal_victims_incident_date ? infoCollection.criminal_victims_incident_date : new Date()} 
                        disabled={this.props.isSuperUser == 'False' || !this.props.editable} />
                </div>
                <div onChange={(e) => this.props.onRadioChange(e)} className="question-container w-100 d-flex align-items-center radio mb-5 flex-wrap" >
                    <div className="title">Hefur brotið verið kært til lögreglu?</div>
                    <input className="ml-auto mr-2" {...disabled} type="radio" id="criminal_victims_charge_yes" name="criminal_victims_charge" checked={infoCollection.criminal_victims_charge === true} value={true}/>
                    <label className="mr-4 mb-0" htmlFor="criminal_victims_charge_yes">Já</label>
                    <input className="mr-2" {...disabled} type="radio" id="criminal_victims_charge_no" name="criminal_victims_charge" checked={infoCollection.criminal_victims_charge === false} value={false}/>
                    <label className="mb-0" htmlFor="criminal_victims_charge_no">Nei</label>
                </div>
                {infoCollection.criminal_victims_charge && 
                    <div className="question-container w-100 d-flex align-items-center radio mb-5 flex-wrap">
                        <label htmlFor="criminal_victims_charge_date">Hvenær var brotið kært til lögreglu?</label>
                        <DatePicker
                            onChange={(value) => this.props.onDateChange(value, "criminal_victims_charge_date")}
                            value={infoCollection.criminal_victims_charge_date ? infoCollection.criminal_victims_charge_date : new Date()} 
                            disabled={this.props.isSuperUser == 'False' || !this.props.editable}/>
                        <label htmlFor="criminal_victims_charge_where">Til hvaða embættis lögreglunnar var brotið kært?</label>
                        <input type="text" id="criminal_victims_charge_where" value={infoCollection.criminal_victims_charge_where} onChange={(e) => this.props.onChange(e)}></input>
                    </div>
                }
            </fieldset>
        )
    }
}

export default CriminalVictimQuestions;
