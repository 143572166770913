import React from 'react'
import ReactDOM from 'react-dom'
import Estimate from './estimate'
import { store } from '../../store' 

$.fn.estimate = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(Estimate, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-estimate]').estimate();
});