import React from "react";
import { connect } from 'react-redux'


class ApplicationStatus extends React.Component {
    constructor(props) {
        super(props)
    }


    render() {

        return (
            <span className="application-status">{this.props.applications.application.state_display}</span>
        );
    }
}

function mapStateToProps(state) {

    var f = {
        applications: {},
    }
    f.applications = (state.applications === undefined) ? {} : state.applications

    return f;
}

export default connect(mapStateToProps)(ApplicationStatus);