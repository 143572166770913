import React from "react";

class OtherAccidentTypeQuestions extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { infoCollection } = this.props;
        return (
            <fieldset disabled={this.props.isSuperUser == 'False'}>
                <label htmlFor="other_accident_type_description">Lýstu tjóninu eins vel og þú getur, við munum svo hafa samband við þig.</label>
                <input type="text" id="other_accident_type_description" value={infoCollection.other_accident_type_description} onChange={(e) => this.props.onChange(e)}></input>
            </fieldset>
        )
    }
}

export default OtherAccidentTypeQuestions;
