import React from 'react';
import { applicationTypes, updateApplicationTypes, createApplicationTypes, healthProviderEmailTypes } from './actions'
import { deleteSettlementTypes, createSettlementTypes } from '../settlement-line/actions'

const initialState = {
    isFetching: false,
    results: [],
    application: {},
    healthProviderEmailLoading: false,
    healthProviderEmailMessage: ''
}




export function applications(state = initialState, action) {

    switch (action.type) {

        case deleteSettlementTypes.RECEIVE:
            state = {...state}
            state.application.settlements = state.application.settlements.filter(f => f.id !== action.id).sort((a, b) => a.sort_order - b.sort_order)
            return state

        case createSettlementTypes.RECEIVE:
            state = {...state}
            state.application.settlements = action.data
            return state

        case createApplicationTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state

        case createApplicationTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.results = action.data

            return state

        case updateApplicationTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state

        case updateApplicationTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.application = action.data

            return state

        case applicationTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state

        case applicationTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.application = action.data

            return state

        case healthProviderEmailTypes.REQUEST:
            state = { ...state}
            state.healthProviderEmailMessage = ''
            state.healthProviderEmailLoading = true
            
            return state

        case healthProviderEmailTypes.ERROR:
        case healthProviderEmailTypes.RECEIVE:
            state = { ...state }
            state.healthProviderEmailLoading = false
            state.healthProviderEmailMessage = action.data
            
            return state

        default:
            return state
    }
}