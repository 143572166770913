import React from "react";
import { b64DecodeUnicode } from "../../utils"
class Terms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    onChangeCheckbox(e) {
        const { value, id } = e.currentTarget
        const checked = e.currentTarget.checked
        console.log(checked, id)
        this.props.onChange(checked, id)
    }

    render() {
        let readOnly = {}
        if (!this.props.editable)  {
            readOnly['readOnly'] = "readonly"
        }
        return (
            <div>
                <fieldset disabled={this.props.isSuperUser == 'False'}>
                    <div className="container-fluid step-terms px-0">
                        <div className="row mb-4">
                            <div className="col-16">
                                <h2>Skilmálar</h2>
                                <div dangerouslySetInnerHTML={{__html: b64DecodeUnicode(this.props.terms)}}></div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-16 form-group form-check">
                                <input className="form-check-input" type="checkbox" id="terms" onChange={(e) => this.onChangeCheckbox(e)} />
                                <label className="form-check-label" htmlFor="terms">Ég samþykki skilmálana</label>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        )
    }
}

Terms.defaultProps = {
    editable: true
}

export default Terms;
