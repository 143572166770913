import React from "react";

class UpdatedView extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <></>
    )
  }
}

export default UpdatedView;
