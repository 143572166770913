
import React from "react";
import { connect } from 'react-redux'
import { SvgCheckMark } from '../svg'
import { setStep } from '../../api/step-renderer/actions'
import { APPLICATION_PROCESS_STEPS, APPLICATION_PROCESS_STEPS_READABLE } from "../../constants";
//import { SvgArrowRight, SvgArrowLeft } from "../svg";
import { Link } from "react-router-dom";


class BreadCrumbs extends React.Component {

    constructor(props){
        super(props)

    }

    render() {
        let currentIndex = this.props.stepRenderer.index
        
        return (
            <>
                    <div className={`bread-crumbs-container row justify-content-center${currentIndex !== 0 ? ' xs-pt-50' : ''}`}>
                        <div className="col-16 breadcrumbs-col d-flex">
                            { APPLICATION_PROCESS_STEPS.map((val, idx) => {
                                let classNames = ''
                                if(idx < currentIndex) {
                                    classNames = 'previous'
                                } else if (idx === currentIndex) {
                                    classNames = 'current'
                                } else {
                                    classNames = 'next'
                                }
                                return <div className={`col-lg-3 steps-col flex-grow d-flex align-items-center  ${currentIndex == idx ? ' current-container' : ''}`}><span className={`bread-crumbs steps ${classNames} ${idx}`}>
                                    { idx < currentIndex ?
                                        <><span className="check" onClick={() => this.props.dispatch(setStep(idx))}><SvgCheckMark /></span><span className="name">{APPLICATION_PROCESS_STEPS_READABLE[idx]}</span></>
                                        :
                                        <><span className="number">{idx+1}</span><span className="name">{APPLICATION_PROCESS_STEPS_READABLE[idx]}</span></>
                                    }
                                </span>
                                {/* { idx < 3 ? <><span className="line"></span></> : ''} */}
                                </div>
                            })} 
                        </div>  
                    </div> 

            </>
        )
    }

}

BreadCrumbs.defaultProps = { }

function mapStateToProps(state) {

    var f = {
        stepRenderer: {},
    }
    f.stepRenderer = (state.stepRenderer === undefined) ? {} : state.stepRenderer

    return f;
}

export default connect(mapStateToProps)(BreadCrumbs);