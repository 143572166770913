import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const insuranceProviderTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'INSURANCE_PROVIDER');

export const requestInsuranceProvider = () => {
  return {
      type: insuranceProviderTypes.REQUEST,
  };
};

export const receiveInsuranceProvider = (response, query = {}) => {
  return {
      type: insuranceProviderTypes.RECEIVE,
      query: query,
      data: response,
  };
};

export const receiveInsuranceProviderError = () => {
  return {
      type: insuranceProviderTypes.ERROR,
  };
};

export const fetchInsuranceProvider = () => {
  return dispatch => {
      dispatch(requestInsuranceProvider());

      let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',

      }

      return fetch(`/api/v1/insuranceproviders/`, {
          headers: headers
      })
          .then(parseJSON)
          .then(response => {
              try {
                  dispatch(receiveInsuranceProvider(response));
              } catch (e) {
                  dispatch(receiveInsuranceProviderError(response))
              }


          })
          .catch(handleNetworkError);
  }
}