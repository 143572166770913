import React from 'react'
import ReactDOM from 'react-dom'
import { store } from '../../store' 
import ApplicationDetail from './application-detail';

$.fn.applicationDetail = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(ApplicationDetail, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-application-detail]').applicationDetail();
});