import React from "react";

import CriminalVictimQuestions from "./criminal-victim"
import WorkAccidentQuestions from "./work-accident"
import TrafficAccidentQuestions from "./traffic-accident"
import MedicalMistakeQuestions from "./medical-mistake"
import OtherAccidentTypeQuestions from "./other-accident-type"

class AdditionalQuestions extends React.Component {
  constructor(props) {
    super(props)
    this.getQuestions = this.getQuestions.bind(this)
  }

  getQuestions(accidentType) {
    switch(accidentType.name) {
      case 'Líkamsárás':
        return <CriminalVictimQuestions {...this.props}/> 
 
      case 'Vinnuslys':
        return <WorkAccidentQuestions {...this.props}/>;

      case 'Umferðarslys':
        return <TrafficAccidentQuestions {...this.props}/>;

      case 'Læknamistök':
        return <MedicalMistakeQuestions {...this.props}/>;

      case 'Annað':
        return <OtherAccidentTypeQuestions {...this.props}/>;

      default: 
        return <></>
    }
  }

  render() {
    return (
      <div>
        {this.getQuestions(this.props.accident_type)}
      </div>
    )
  }
}

export default AdditionalQuestions;
