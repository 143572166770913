import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const profileTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'PROFILE');

export const requestProfile = () => {
  return {
      type: profileTypes.REQUEST,
  };
};

export const receiveProfile = (response, query = {}) => {
  return {
      type: profileTypes.RECEIVE,
      query: query,
      data: response,
  };
};

export const receiveProfileError = () => {
  return {
      type: profileTypes.ERROR,
  };
};

export const fetchProfile = (applicantId) => {
  return dispatch => {
      dispatch(requestProfile());

      let headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',

      }
      let url = ''

      if (applicantId) {
          url = `/api/v1/profile/${applicantId}/`
      } else {
          url = `/api/v1/profile/`
      }

      return fetch(url, {
          headers: headers
      })
          .then(parseJSON)
          .then(response => {
              try {
                  dispatch(receiveProfile(response));
              } catch (e) {
                  dispatch(receiveProfileError(response))
              }


          })
          .catch(handleNetworkError);
  }
}