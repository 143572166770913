
import moment from "moment";
import React from "react";
import Iframe from "react-iframe";
import { connect } from 'react-redux'
import { fetchApplication, updateApplication } from '../../api/applications/actions'



class LogItem extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
        }

    }

    render() {
        const { logItem, superuser } = this.props
        return (
            <div className="card email">
                <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target={`#collapselogitem${logItem.id}`} aria-expanded="true" aria-controls={`collapselogitem${logItem.id}`}>
                            {logItem.message} <span>{moment(logItem.timestamp).format("D.M.YYYY")} kl. {moment(logItem.timestamp).format("HH:mm:ss")}</span>
                        </button>
                    </h2>
                </div>
                {superuser &&
                    <div id={`collapselogitem${logItem.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">

                        <div className="card-body">
                            {logItem.user &&
                                <>
                                    <strong>Notandi:</strong> {logItem.user_txt}<br />
                                </>
                            }
                        </div>
                    </div>
                }
            </div>


        )
    }

}

LogItem.defaultProps = {}


export default LogItem;