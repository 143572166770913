import React from "react";
import { connect } from 'react-redux'
import { initStepper, setStep } from '../../api/step-renderer/actions'
import { APPLICATION_PROCESS_STEPS } from '../../constants'
import StepRenderer from '../../components/step-renderer'
import { fetchInsuranceProvider } from "../../api/insurance-provider/actions";
import { fetchAccidentTypes } from "../../api/accident-type/actions";
import { fetchHealthcareProviders } from "../../api/healthcare-provider/actions";
import { fetchProfile } from "../../api/profile/actions";
import { createApplication, updateApplication, fetchApplication } from '../../api/applications/actions'
import { setInfoKey } from '../../api/information-collection/actions'
import { step1validated, step2validated, step3validated, termsvalidated } from '../../utils'
import BreadCrumbs from "../../components/breadcrumbs";
import { POSTAL_CODES } from '../../constants'
import { SvgBackArrow, SvgCaretDown } from './../../components/svg'
import Application from '../application-form/application'
import ApplicationActions from '../../components/application-actions'
import LogItem from "../../components/log-item";
import LogItemEmail from "../../components/log-item-email";
import Settlement from "../settlement/settlement";
import Estimate from "../estimate/estimate";
import moment from "moment";


class ApplicationDetail extends React.Component {
    constructor(props) {
        super(props)
        this.shouldShow = this.shouldShow.bind(this)
    }

    shouldShow(type) {
        switch(type) {
            case 'demand':
                return ['estimate_sent', 'in_progress', 'finished'].includes(this.props.applications.application.state)
            case 'settlement':
                return ['finished', 'claim_sent'].includes(this.props.applications.application.state)
            case 'estimate':
                return ['estimate_sent', 'in_progress', 'finished'].includes(this.props.applications.application.state)
            }
        return false
    }

    calculateEstimateDate(application) {
        let today = moment()
        let todayMonday = moment().startOf('isoWeek')
        if (todayMonday < today) {
            todayMonday = todayMonday.add(1, 'week')
        }
        let oneYearAfter = moment(application.jsondata.accident_date).add(1, 'year')
        let oneYearAfterMonday = moment(application.jsondata.accident_date).add(1, 'year').startOf('isoWeek')
        if (oneYearAfterMonday < oneYearAfter) {
            oneYearAfterMonday = oneYearAfterMonday.add(1, 'week')
        }
        if ( today < oneYearAfterMonday) {
            return oneYearAfterMonday
        } else {
            return todayMonday
        }
    }

    render() {
        const { applicationId, applicantId, store, superuser, applications } = this.props
        const { application } = applications

        return (
            <div className="col-xl-10 col-lg-14 col-15 outer-application-container mt-5">

                <div className="title-container d-flex justify-content-between w-100">
                    <a className="back-arrow" href="/minarsidur/">
                        <SvgBackArrow />
                    </a>
                    <h1 className="w-100">Umsókn #{application.id}  <span><span className="application-status">{application.state_display}</span></span></h1>
                </div>

                <div className="actions-container">
                    <ApplicationActions applications={applications} superuser={superuser} store={store} />
                </div>
                
                { application.state === 'in_progress' &&
                    <div className="alert alert-info">
                        Tillaga að matsgerð verður sjálfkrafa send { this.calculateEstimateDate(application).format("D.M.YYYY")} ef búið er að fylla út nafn læknis og lögfræðings fyrir matsgerð.
                    </div>
                }

                { application.state === 'estimate_sent' && application.application_document.filter(f => f.document_type == 'estimate').length === 0 &&
                    <div className="alert alert-info">
                        Hlaða þarf inn matsgerð svo hægt sé að senda kröfubréf
                    </div>
                }

                <div className="d-flex justify-content-between">
                    <ul className="nav nav-pills pn-buttons mb-5 justify-content-start mt-0" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                aria-controls="home" aria-selected="true">Umsókn</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab"
                                aria-controls="history" aria-selected="false">Saga</a>
                        </li>
                        {superuser ?
                            <>
                                { this.shouldShow('estimate') &&
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="estimate-tab" data-toggle="tab" href="#estimate" role="tab" aria-controls="estimate" aria-selected="false">Matsgerð</a>
                                    </li>
                                }
                                { this.shouldShow('demand') &&
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="demand-tab" data-toggle="tab" href="#demand" role="tab" aria-controls="demand" aria-selected="false">Kröfubréf</a>
                                    </li>
                                }
                                { this.shouldShow('settlement') &&
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="settlement-tab" data-toggle="tab" href="#settlement" role="tab" aria-controls="settlement" aria-selected="false">Uppgjör</a>
                                    </li>
                                }
                            </>
                            :
                            <>
                                {application.state === 'finished' &&
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="settlement-tab" data-toggle="tab" href="#settlement" role="tab" aria-controls="settlement" aria-selected="false">Uppgjör</a>
                                    </li>
                                }
                            </>
                        }
                    </ul>
                </div>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <Application
                            applicationId={applicationId}
                            applicantId={applicantId}
                            superuser={superuser}
                            renderAllSteps={true}
                            shouldSetInfoKeys={true}
                            store={store} />
                    </div>
                    <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                        <div className="header d-flex justify-content-between">
                            <span>Atburður</span>
                            <span>Dags og tímasetning
                                <SvgCaretDown />
                            </span>
                        </div>
                        <div className="accordion" id="accordionExample">
                            {application && application.logs && application.logs.sort((b, a) => {
                                let x = moment(a.timestamp)
                                if (a.email) {
                                    x = moment(a.email.meta.ts*1000)
                                }
                                let y = moment(b.timestamp)
                                if (b.email) {
                                    y = moment(b.email.meta.ts*1000)
                                }
                                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                            }).map(l => {
                                if (l.email) {
                                    return <LogItemEmail logItem={l} superuser={superuser} />
                                } else {
                                    return <LogItem logItem={l} superuser={superuser} />
                                }
                            })}
                        </div>
                    </div>
                    {superuser ?
                        <>
                            <div className="tab-pane fade" id="estimate" role="tabpanel" aria-labelledby="estimate-tab">
                                <Estimate applicationId={applicationId} store={store} />
                            </div>
                            <div className="tab-pane fade" id="demand" role="tabpanel" aria-labelledby="demand-tab">
                                <Settlement applicationId={applicationId} settlementType='demand' store={store} />
                            </div>
                            <div className="tab-pane fade" id="settlement" role="tabpanel" aria-labelledby="settlement-tab">
                                <Settlement applicationId={applicationId} store={store} />
                            </div>
                        </>

                        :
                        <>
                            {application.state === 'finished' &&
                                <div className="tab-pane fade" id="settlement" role="tabpanel" aria-labelledby="settlement-tab">
                                    <div className="row justify-content-center">
                                        <div className="col-16 col-sm-12 col-md-10 col-lg-8 text-center">
                                            <h2>Uppgjör</h2>
                                            <p>Hérna er hægt að sækja uppgjör. Upphæðir verða lagðar inn á reikninginn sem þú tilgreindir innan fárra daga frá uppgjörsdegi.</p>
                                            <a className="btn btn-lg settlement-button mt-5" href={`/api/v1/application/${application.id}/settlement/?rand=${parseInt(Math.random() * 100000)}`} target="_blank">Smelltu hér til að sækja uppgjörið þitt</a>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    var f = {
        applications: {},
    }
    f.applications = (state.applications === undefined) ? {} : state.applications

    return f;
}

export default connect(mapStateToProps)(ApplicationDetail);