import React from "react";

class Step1 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.onChangeRadio = this.onChangeRadio.bind(this);
    }

    onChangeRadio(e) {
        this.props.onChange(e.target.value, 'applicant_type', 'infoCollection')
        if(e.target.value == "same"){
            this.props.onChange("", 'alternative_applicant_ssn', 'infoCollection')
        }
    }

    onChange(e, group) {
        const { value, id } = e.currentTarget
        this.props.onChange(value, id, group)
    }

    render() {
        let readOnly = {}
        let disabled = {}
        if (!this.props.editable)  {
            readOnly['readOnly'] = "readonly"
            disabled['disabled'] = "disabled"
        }
        return (
            <div>
                {this.props.profile &&
                <fieldset disabled={this.props.isSuperUser == 'False'}>
                    <div className="container-fluid step-1">
                        <div className="row mb-4">
                            <div className="col-lg-7 col-16">
                                <label htmlFor="name">Nafn</label>
                                <input id="name" type="text" readOnly="readonly" value={this.props.profile.name} onChange={(e) => this.onChange(e, 'profile')}></input>
                            </div>
                            <div className="col-lg-4 col-8">
                                <label htmlFor="ssn">Kennitala</label>
                                <input id="ssn" type="text" readOnly="readonly" value={this.props.profile.ssn} onChange={(e) => this.onChange(e, 'profile')}></input>
                            </div>
                            <div className="col-lg-4 col-8">
                                <label htmlFor="phone">Símanúmer</label>
                                <input id="phone" type="text" readOnly="readonly" value={this.props.profile.phone} onChange={(e) => this.onChange(e, 'profile')}></input>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-lg-5 col-sm-8">
                                <label className="mb-1" htmlFor="address">Heimilisfang</label>
                                <input {...readOnly} id="address" type="text" value={this.props.profile.address} onChange={(e) => this.onChange(e, 'profile')}></input>
                            </div>
                            <div className="col-lg-3 col-sm-4 col-8">
                                <label className="mb-1" htmlFor="address">Póstnúmer</label>
                                <input {...readOnly} id="postal_code" type="text" value={this.props.profile.postal_code} onChange={(e) => this.onChange(e, 'profile')}></input>
                            </div>
                            <div className="col-lg-2 col-sm-4 col-8 ">
                                <label className="mb-1" htmlFor="address" className="town">Bæjarfélag</label>
                                <input {...readOnly} id="city" type="text" readOnly="readonly" value={this.props.profile.city} onChange={(e) => this.onChange(e, 'profile')}></input></div>
                            <div className="col-lg-5 offset-lg-1">
                                <label className="mb-1 mt-2 mt-md-0 " htmlFor="email">Netfang</label>
                                <input {...readOnly} id="email" type="text" value={this.props.profile.email} onChange={(e) => this.onChange(e, 'profile')}></input>
                            </div>
                        </div>

                    </div>
                </fieldset>
                }
                <div className="container-fluid mt-5 mb-3 other-applicant d-flex">
                    <div className="row w-100">
                        <div className="col-lg-12 col-sm-16 d-flex align-items-center other-applicant-col" onChange={this.onChangeRadio}>
                            <div className="d-flex align-items-center">
                                <input {...disabled} className="mr-2" id="same-applicant" type="radio" name="applicant" value="same" checked={this.props.infoCollection.applicant_type==="same" || this.props.infoCollection.applicant_type === undefined}></input>
                                <label className="mb-1 mr-3" htmlFor="same-applicant">Tjónþoli er sami og umsækjandi</label>
                            </div>
                            <div className="d-flex align-items-center">
                                <input {...disabled} className="mr-2" id="alternative-applicant" type="radio" name="applicant" value="alternative" checked={this.props.infoCollection.applicant_type==="alternative"}></input>
                                <label className="mb-1 mt-md-0" htmlFor="alternative-applicant">Skrá annan tjónþola</label>
                            </div>
                        </div>
                        {this.props.infoCollection.applicant_type === "alternative" &&
                            <div className="col-lg-6 col-16 mt-3">
                                <label htmlFor="alternative-applicant-ssn">Kennitala tjónþola</label>
                                <input {...readOnly} id="alternative_applicant_ssn" type="text" value={this.props.infoCollection.alternative_applicant_ssn} onChange={(e) => this.onChange(e, 'infoCollection')}></input>
                                ATH Við mælum alltaf með að tjónþoli skrái sig sjálfur inn með eigin rafrænum skilríkjum og fylli út umsókn til þess að auðvelda ferlið.
                            </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}

Step1.defaultProps = {
    editable: true
}

export default Step1;
