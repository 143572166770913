import React from 'react'

//basic svg
export const SvgCheckMark = () => {
    return (
        <svg width="23px" height="22px" viewBox="0 0 23 22" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Login-6" transform="translate(-747.000000, -304.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="noun_checkmark_364805-copy" transform="translate(747.000000, 304.000000)">
                        <polygon id="Path" points="7.89094136 22 0 14.1345698 2.95034622 11.3180782 7.48170304 15.8355754 19.702406 0 23 2.42172067"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const SvgBackArrow = () => {
    return (<svg width="13px" height="20px" viewBox="0 0 13 20" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
            <g id="Umsoknir-03" transform="translate(-330.000000, -234.000000)" stroke="#6B8F9C" strokeWidth="3">
                <polyline id="Path-2" points="341 252 333 244 341 236"></polyline>
            </g>
        </g>
    </svg>)
}

export const SvgCaretDown = () => {
    return (
        <svg width="10px" height="4px" viewBox="0 0 10 4" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Umsoknir-009" transform="translate(-1507.000000, -459.000000)" stroke="#1F1F1F">
                    <polyline id="Path-4-Copy" points="1508 459 1512 463 1516 459"></polyline>
                </g>
            </g>
        </svg>
    )
}

export const SvgDocument = () => {
    return (
        <svg width="14px" height="19px" viewBox="0 0 14 19" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Umsoknir-11" transform="translate(-512.000000, -706.000000)" stroke="#6B8F9C">
                    <polygon id="Path-5" points="525 724 513 724 513 707 520.493381 707 525 711.436567"></polygon>
                </g>
            </g>
        </svg>
    )
}